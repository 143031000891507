import React from "react";
import { Modal } from "react-bootstrap";
import WhatsappPhoneNumberForm from "../whatsappBot/WhatsappPhoneNumberForm";
import EmailForm from "../whatsappBot/EmailForm";
import { useTranslation } from "react-i18next";
import { isValidEmail } from "../../utils/validFormData";

const ApplyForJobModal = ({
  isModalVisible,
  resetForm,
  formStatus,
  isUsingEmailForm,
  setIsUsingEmailForm,
  errorMessage,
  setErrorMessage,
  handleEmailSubmission,
  handleWhatsappSubmission,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}) => {
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isUsingEmailForm) {
      if (!email) {
        setErrorMessage("Email is required!");
      } else if (!isValidEmail(email)) {
        setErrorMessage("Email is not valid");
      } else {
        setErrorMessage("");
        handleEmailSubmission({ email, category: "career" });
      }
    } else {
      if (!phoneNumber) {
        setErrorMessage("Please provide a phone number");
      } else {
        setErrorMessage("");
        handleWhatsappSubmission({phone_number:phoneNumber});
      }
    }
  };
  return (
    <Modal show={isModalVisible} onHide={resetForm} centered>
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body style={{ padding: "0 30px 20px 30px" }}>
        <div className="w-100 d-flex flex-column align-items-center ">
          <div>
            <img
              src="/images/New Robot.webp"
              alt=""
              style={{ width: "125px", height: "125px" }}
            />
          </div>
          <div className="w-100 mt-5 mb-4 d-flex flex-column justify-content-start align-items-start">
            <h5 style={{ fontSize: "16px", color: "#1A1A1A" }}>
              We're here to lend a hand!
            </h5>
            <h2
              style={{
                fontSize: "38px",
                fontWeight: "600",
                color: "#1A1A1A",
                letterSpacing: "-0.01em",
              }}
              className="mt-3"
            >
              Join our team
            </h2>
          </div>
        </div>
        {formStatus.isSuccess && (
          <div className={`alert alert-success`} style={{ padding: 10 }}>
            {t("connectnowmodel.wewillconnectsoon")}
          </div>
        )}
        {formStatus.error && (
          <div className={`alert alert-danger`} style={{ padding: 10 }}>
            {formStatus.error}
          </div>
        )}
        <form
          className="d-flex flex-column"
          onSubmit={handleSubmit}
        >
          {isUsingEmailForm ? (
            <EmailForm email={email} setEmail={setEmail} error={errorMessage} />
          ) : (
            <WhatsappPhoneNumberForm
              phoneNumber={phoneNumber}
              setPhoneNumber={setPhoneNumber}
              error={errorMessage}
            />
          )}
          <button
            type="submit"
            className="btn whatsapp_phonenumber_submit_btn"
            disabled={
              (!email && phoneNumber.length <= 4) || formStatus.isLoading
            }
          >
            {formStatus.isLoading && (
              <i
                className="fa fa-spinner fa-spin mr-2 text-white"
                aria-hidden="true"
              />
            )}
            <span
              style={{ fontSize: "16px", fontWeight: "600" }}
              className="text-white"
            >
              {t("connectnowmodel.submit")}
            </span>
          </button>
        </form>

        <div className="w-100 mt-5 d-flex justify-content-center">
          <p style={{ fontSize: "16px" }}>
            Continue with {isUsingEmailForm ? "mobile number " : "email "}
            <button
              className="text-lowercase p-0"
              disabled={formStatus.isLoading}
              style={{
                fontSize: "16px",
                color: "#5928d1",
                cursor: formStatus.isLoading ? "wait" : "pointer",
                border: "none",
                background: "transparent",
                fontWeight: "600",
              }}
              onClick={() => {
                setIsUsingEmailForm(!isUsingEmailForm);
              }}
            >
              {" "}
              click here
            </button>
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApplyForJobModal;
