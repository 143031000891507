import React from "react";
import {Helmet} from "react-helmet";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  return (
    <>
      <Helmet>
        <title>Our Privacy Policy</title>
        <meta
          name="description"
          content="We take the privacy and security of our customer's and user's personal information very seriously. This Privacy Policy outlines our practices regarding the collection, use, and disclosure of personal information that we obtain through our website, software products, and services"
        />
      </Helmet>
      <div style={{paddingTop:"140px"}} className="privacy-policies container-hire">
        <div className="col-md-6 mt-5">
          <h1 className="mb-3">{t("privacypolicy.title")}</h1>
          <p className="pp-text">
          {t("privacypolicy.desc")}
          </p>
          <p>
            <ul className="my-4">
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title1")}</strong> {t("privacypolicy.desc1")}
              </li>
              <li className="mb-3 pp-text">
                <strong> {t("privacypolicy.title2")}</strong> {t("privacypolicy.desc2")}
              </li>
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title3")}</strong> {t("privacypolicy.desc3")}
              </li>
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title4")}</strong>{t("privacypolicy.desc4")}
              </li>
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title5")}</strong> {t("privacypolicy.desc5")}
              </li>
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title6")}</strong> {t("privacypolicy.desc6")}
                contact@fxis.ai.
              </li>
              <li className="mb-3 pp-text">
                <strong>{t("privacypolicy.title7")}</strong> {t("privacypolicy.desc7")}
              </li>
            </ul>
          </p>
          <p className="text-look">
          {t("privacypolicy.footer")}{" "}
            <a className="link-job" href="mailto:contact@fxis.ai">
            contact@fxis.ai.
            </a>
          </p>
        </div>
        <div className="col-md-6 mt-0 main-img2">
          <img
            fetchpriority="high"
            src="images/privacy-policies.svg"
            className="privacy-policies-image"
            alt="img"
          />
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
