import {
    LOG_IN_ERR,
    LOG_IN_REQ,
    REGISTER,
    REGISTER_ERR,
    REGISTER_REQ,
    RESEND_TOKEN,
    RESET_AUTH_MESSAGES_AND_ERRORS,
    TOGGLE_AUTH_LOADER,
    VERIFY_ACC,
    VERIFY_ACC_ERR,
    HIRE_NOW_REGISTER,
    HIRE_NOW_REGISTER_ERR,
    HIRE_NOW_REGISTER_REQ,
    HIRE_NOW_DATA,
    INCREMENT,
    DECREMENT,
    RESET,
    REQ_DATA,
    TECH_DATA
} from "../actions/actionTypes";
import { setStoreData } from "../../Store/Storage";

const { LOG_IN, LOG_OUT } = require("../actions/actionTypes");

const localStorageUser = JSON.parse(localStorage.getItem("user"));

const localStorageTabs = JSON.parse(localStorage.getItem("tabs"));

const tabs =
    localStorageUser != null && localStorageTabs != null
        ? localStorageTabs
        : [];

const loginState = localStorageUser != null;

const initialState = {
    count: 0,
    email: null,
    phone: null,
    timeValue: null,
    ventureValue: null,
    ipoValue: null,
    employeesValue: null,
    webSiteLink: null,
    technolgies: [],
    field: null,
    isLoggedIn: loginState,
    user: localStorageUser,
    tabs: tabs,
    verifyAccountStatus: 0,
    errors: {
        verify: null,
        register: null,
        hireNowRegister: null,
        login: null
    },
    registerSuccessMessage: null,
    hireNowRegisterSuccessMessage: null,
    loaders: {
        verify: false,
        register: false,
        hireNowRegister: false,
        login: false
    }
};

const sessionHandler = (state = initialState, action) => {
    switch (action.type) {
        case INCREMENT:
            return {
                ...state,
                count: state.count + 1
            };

        case DECREMENT:
            return {
                ...state,
                count: state.count - 1
            };

        case RESET:
            return {
                ...state,
                count: 0
            };

        case HIRE_NOW_DATA:
            return {
                ...state,
                email: action.payload,
            };

        case TECH_DATA:
            return{
                ...state,
                technolgies: action.payload.technology,
                field: action.payload.field
            }    

        case REQ_DATA:
            return {
                ...state,
                phone: action.payload.phoneInput,
                timeValue: action.payload.timeValue,
                ventureValue: action.payload.ventureValue,
                ipoValue: action.payload.ipoValue,
                employeesValue: action.payload.employeesValue,
            }
        case LOG_IN_REQ:
            return {
                ...state,
                isLoggedIn: false,
                loaders: {
                    ...state.loaders,
                    login: true
                },
                errors: {
                    ...state.errors,
                    login: null
                }
            };
        case LOG_IN:
            setStoreData("user", action.user);
            setStoreData("token", action.token, true);
            setStoreData("tabs", action.tabs);
            return {
                ...state,
                isLoggedIn: true,
                user: action.user,
                tabs: action.tabs,
                loaders: {
                    ...state.loaders,
                    login: false
                },
                errors: {
                    ...state.errors,
                    login: null
                }
            };
        case LOG_IN_ERR:
            return {
                ...state,
                isLoggedIn: false,
                loaders: {
                    ...state.loaders,
                    login: false
                },
                errors: {
                    ...state.errors,
                    login: action.payload
                }
            };
        case LOG_OUT:
            localStorage.removeItem("user");
            localStorage.removeItem("token");
            localStorage.removeItem("tabs");
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                tabs: null
            };
        case TOGGLE_AUTH_LOADER:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    [action.loaderType]: action.value
                }
            };
        case VERIFY_ACC:
            setStoreData("user", action.user);
            setStoreData("token", action.token, true);
            setStoreData("tabs", action.tabs);
            return {
                isLoggedIn: true,
                user: action.user,
                tabs: action.tabs,
                verifyAccountStatus: action.custom_status,
                loaders: {
                    ...state.loaders,
                    verify: false
                },
                errors: {
                    ...state.errors,
                    verify: null
                }
            };
        case VERIFY_ACC_ERR:
            return {
                ...state,
                isLoggedIn: false,
                user: localStorageUser,
                tabs: tabs,
                verifyAccountStatus: 0,
                loaders: {
                    ...state.loaders,
                    verify: false
                },
                errors: {
                    ...state.errors,
                    verify: action.payload
                }
            };
        case RESEND_TOKEN:
            return {
                ...state,
                isLoggedIn: false,
                user: localStorageUser,
                tabs: tabs,
                verifyAccountStatus: action.custom_status,
                loaders: {
                    ...state.loaders,
                    verify: false
                },
                errors: {
                    ...state.errors,
                    verify: action.message
                }
            };
        case REGISTER_REQ:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    register: true
                },
                errors: {
                    ...state.errors,
                    register: null
                },
                registerSuccessMessage: null
            };
        case REGISTER:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    register: false
                },
                errors: {
                    ...state.errors,
                    register: null
                },
                registerSuccessMessage: action.payload
            };
        case REGISTER_ERR:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    register: false
                },
                errors: {
                    ...state.errors,
                    register: action.payload
                },
                registerSuccessMessage: null
            };

        case HIRE_NOW_REGISTER_REQ:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    hireNowRegister: true
                },
                errors: {
                    ...state.errors,
                    hireNowRegister: null
                },
                hireNowRegisterSuccessMessage: null
            };

        case HIRE_NOW_REGISTER:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    hireNowRegister: false
                },
                errors: {
                    ...state.errors,
                    hireNowRegister: null
                },
                hireNowRegisterSuccessMessage: action.payload
            };

        case HIRE_NOW_REGISTER_ERR:
            return {
                ...state,
                loaders: {
                    ...state.loaders,
                    hireNowRegister: false
                },
                errors: {
                    ...state.errors,
                    hireNowRegister: action.payload
                },
                hireNowRegisterSuccessMessage: null
            };

        case RESET_AUTH_MESSAGES_AND_ERRORS:
            return {
                ...state,
                errors: {
                    verify: null,
                    register: null,
                    login: null
                },
                registerSuccessMessage: null,
                loaders: {
                    verify: false,
                    register: false,
                    login: false
                }
            };

        default:
            return state;
    }
};

export default sessionHandler;
