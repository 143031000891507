import React from "react";
import { useHistory } from "react-router-dom";
import "./NotFound.css"

const NotFound = () => {
  const history = useHistory();

  const handleGoHome = () => {
    history.push("/"); // Redirects to the homepage
  };

  return (
    <div className="not-found-container">
      <div className="text-container">
        <h1>404</h1>
        <img
          src="/images/Group.webp" 
          alt="404 Robot"
          className="overlay-image"
        />
        <p>OOPS! PAGE NOT FOUND</p>
        <button onClick={handleGoHome}>GO TO HOMEPAGE</button>
      </div>
    </div>
  );
};

export default NotFound;
