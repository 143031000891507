import { loadJQuery } from "../actions/javascriptLoadingActions";
import { CHECK_LOAD_JQUERY } from "../actions/actionTypes"

const initialState = {
    jqueryLoadstate : 0
}

const loadJqueyCode = (dispatcher) => {
        let jq = document.createElement("script");
        jq.src = process.env.REACT_APP_BASE_URL+"js/jquery-3.3.1.min.js?ca93809a-cd54-40d0-ad2c-a763aba41894";
        jq.type = "text/javascript";
        jq.id = "jqueryId";
        jq.onload = () => {
            dispatcher(loadJQuery());
        };
        document.body.appendChild(jq);
}

// const loadJavascriptCode = (dispatcher) => {
//             let dj = document.createElement("script");
//             dj.src = process.env.REACT_APP_BASE_URL + "js/javascripts.js?ca93809a-cd54-40d0-ad2c-a763aba41894";
//             dj.type = "text/javascript";
//             dj.id = "javaScriptId";
//             dj.async = 1;
//             dj.defer = 1;
//             dj.onload = () => {
//                 dispatcher(loadJavascript());
//             };
//             document.body.appendChild(dj);
// }

const javascriptHandler = (state = initialState, action) => {
    switch(action.type) {
        case CHECK_LOAD_JQUERY :
            let jqueryEle = document.getElementById('jqueryId');
            if(jqueryEle == null) {
                loadJqueyCode(action.dispatcher);
                return state;
            } else {
                return {
                    ...state,
                    jqueryLoadstate : 2
                }
            }
        default :
            return state;
    }
}

export default javascriptHandler;
