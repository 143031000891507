import ProjectOutsourcing from "../Components/Home/ServiceSection/ProjectOutsourcing";
import OnDemandDe from "../Components/Home/ServiceSection/OnDemandDev";
import TechandTalent from "../Components/Home/ServiceSection/TechandTalent";
export const DEFAULT_TABS = [
  // {
  //     id: 1,
  //     title: 'Home',
  //     path: '#home',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 2,
  //     title: 'About Us',
  //     path: '#about',
  //     partialParentPath: '/'
  // },
  // {
  //     id: 3,
  //     title: 'Services',
  //     path: '#services',
  //     div_id: 's_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'service_dropdown_toggle',
  //     subTabs: [
  //         {title: 'Services We Offer', path: '#services', partialParentPath: '/'},
  //         {title: 'Industries We Work In', path: '#industries', partialParentPath: '/'},
  //         {title: ' Technologies We Use', path: '#technologies', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 4,
  //     title: 'Products',
  //     path: '#products',
  //     div_id: 'p_dropdown',
  //     class: 's-dropdown',
  //     icon_id: 'product_dropdown_toggle',
  //     subTabs: [
  //         {title: 'H+Tree', path: '#p_hplus_tree', partialParentPath: '/'},
  //         {title: 'F(x) Data Cloud', path: '#p_fx_data_clouds', partialParentPath: '/'},
  //         {title: 'F(x) Optimizer', path: '#p_fx_optimizer', partialParentPath: '/'},
  //         {title: 'F(x) ERP', path: '#p_fx_erp', partialParentPath: '/'},
  //     ],
  //     partialParentPath: '/'
  // },
  // {
  //     id: 5,
  //     title: 'Contact Us',
  //     path: '#contact',
  //     partialParentPath: '/'
  // },
];

export const PHASE_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "rejected",
  4: "completed",
};

export const TIMELINE_TAIL_STATUS_CLASSES = {
  1: "pending",
  2: "progress",
  3: "pending",
  4: "completed",
};

export const USER_PROGRESS_STATUS_CLASSES = {
  1: "secondary",
  2: "success",
  3: "danger",
  4: "light",
};

export const MAX_FILE_UPLOAD_SIZE = 50;

export const MAX_PAGINATION_ITEMS = 5;

export const DEVELOPER_PROCESS_STATUS = [
  { name: "In Process", value: 2 },
  { name: "Rejected", value: 3 },
  { name: "Completed", value: 4 },
];

export const OurCultureData = [
  {
    descp: "ourculture.descp1",
    highlight: "ourculture.highlight1",
    descs: "ourculture.descs1",
    title: "ourculture.title1",
    image: "../images/ourculture/GlobalCulture.webp",
  },
  {
    descp: "ourculture.descp2",
    highlight: "ourculture.highlight2",
    descs: "ourculture.descs2",
    title: "ourculture.title2",
    image: "../images/ourculture/WokeCulture.webp",
  },
  {
    descp: "ourculture.descp3",
    highlight: "ourculture.highlight3",
    descs: "ourculture.descs3",
    title: "ourculture.title3",
    image: "../images/ourculture/RadicalCondor.webp",
  },
  {
    descp: "ourculture.descp4",
    highlight: "ourculture.highlight4",
    descs: "ourculture.descs4",
    title: "ourculture.title4",
    image: "../images/ourculture/FriendlyWorkCulture.webp",
  },
  {
    descp: "ourculture.descp5",
    highlight: "ourculture.highlight5",
    descs: "ourculture.descs5",
    title: "ourculture.title5",
    image: "../images/ourculture/HighPerformance.webp",
  },
  {
    descp: "ourculture.descp6",
    highlight: "ourculture.highlight6",
    descs: "ourculture.descs6",
    title: "ourculture.title6",
    image: "../images/ourculture/HardWorkingTeam.webp",
  },
]
export const HireExExpertsCompany = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-4",
      "bg-5",
      "bg-6",
    ],

    row_2_images: [
      "bg-7",
      "bg-8",
      "bg-9",
      "bg-10",
      "bg-12",
      "bg-11",
    ],

    row_3_images: [
    "bg-15",
      "bg-13",
      "bg-14",
      "bg-17",
      "bg-18",
      "bg-16",
    ],
  },
  {
    id: 1,
    row_1_images: [
     "bg-19",
      "bg-20",
      "bg-24",
      "bg-21",
      "bg-23",
      "bg-22",
    ],

    row_2_images: [
    "bg-25",
      "bg-26",
      "bg-27",
      "bg-28",  
      "bg-29",
      "bg-30",
    ],

    row_3_images: [
      "bg-32",
      "bg-35",
      "bg-33",
      "bg-34",
      "bg-31",
      "bg-36",
    ],
  },
  {
    id: 1,
    row_1_images: [
     "bg-37",
      "bg-38",
      "bg-39",
      "bg-40",
      "bg-42",
      "bg-41",
    ],

    row_2_images: [],

    row_3_images: [],
  },
];

export const HireExExpertsCompanyTablet = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-4",
    ],

    row_2_images: [
      "bg-5",
      "bg-6",
      "bg-7",
      "bg-8",
    ],

    row_3_images: [
      "bg-9",
      "bg-10",
      "bg-12",
      "bg-11",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "bg-15",
      "bg-13",
      "bg-14",
      "bg-17",
    ],

    row_2_images: [
     "bg-18",
      "bg-16",
      "bg-19",
      "bg-20",
    ],

    row_3_images: [
      "bg-24",
      "bg-21",
      "bg-23",
      "bg-22",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "bg-25",
      "bg-26",
      "bg-27",
      "bg-28",  
    ],

    row_2_images: [
      "bg-29",
      "bg-30",
      "bg-32",
      "bg-35",
    ],

    row_3_images: [
      "bg-33",
      "bg-34",
      "bg-31",
      "bg-36",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "bg-37",
      "bg-38",
      "bg-39",
      "bg-40",
    ],

    row_2_images: [
      "bg-42",
      "bg-41",
    ],

    row_3_images: [""],
  },
];

export const HireExExpertsCompanyMobile = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
    ],

    row_2_images: [
     "bg-4",
      "bg-5",
      "bg-6",
    ],

    row_3_images: [
     "bg-7",
      "bg-8",
      "bg-9",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "bg-10",
      "bg-12",
      "bg-11",
    ],

    row_2_images: [
      "bg-15",
      "bg-13",
      "bg-14",
    ],

    row_3_images: [
      "bg-17",
      "bg-18",
      "bg-16",
    ],
  },
  {
    id: 2,
    row_1_images: [
    "bg-19",
      "bg-20",
      "bg-24",
    ],

    row_2_images: [
      "bg-21",
      "bg-23",
      "bg-22",
    ],

    row_3_images: [
      "bg-25",
      "bg-26",
      "bg-27",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "bg-28",  
      "bg-29",
      "bg-30",
    ],

    row_2_images: [
      "bg-32",
      "bg-35",
      "bg-33",
    ],

    row_3_images: [
      "bg-34",
      "bg-31",
      "bg-36",
    ],
  },
  {
    id: 4,
    row_1_images: [
      "bg-37",
      "bg-38",
      "bg-39",
    ],

    row_2_images: [
     "bg-40",
      "bg-42",
      "bg-41",
    ],

    row_3_images: [
      
    ],
  },
];

export const TechStackFullStack = [
  {
    id: 0,
    row_1_images: [
      "bg-68",
      "bg-69",
      "bg-70",
      "bg-71",
      "bg-72",
      "bg-73",
      "bg-74",
      "bg-75",
    ],

    row_2_images: [
      "bg-76",
      "bg-77",
      "bg-78",
      "bg-79",
      "bg-80",
      "bg-81",
      "bg-82",
      "bg-83",
    ],

    row_3_images: [
      "bg-84",
      "bg-85",
      "bg-86",
      "bg-87",
      "bg-88",
      "bg-89",
      "bg-90",
      "bg-91",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-92",
      "bg-93",
      "bg-94",
      "bg-95",
      "bg-96",
      "bg-97",
      "bg-98",
      "bg-99",
    ],

    row_2_images: [
      "bg-100",
      "bg-101",
      "bg-102",
      "bg-103",
      "bg-104",
      "bg-105",
      "bg-106",
      "bg-107",
    ],

    row_3_images: [
      "bg-108",
      "bg-109",
      "bg-110",
      "bg-111",
      "bg-112",
      "bg-113",
      "bg-114",
      "bg-115",
    ],
  },
];

export const TechStackFullStackTablet = [
  {
    id: 0,
    row_1_images: [
      "bg-68",
      "bg-69",
      "bg-70",
      "bg-71",
    ],

    row_2_images: [
      "bg-72",
      "bg-73",
      "bg-74",
      "bg-75",
    ],

    row_3_images: [
      "bg-76",
      "bg-77",
      "bg-78",
      "bg-79",
    ],
    row_4_images: [
      "bg-80",
      "bg-81",
      "bg-82",
      "bg-83",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-84",
      "bg-85",
      "bg-86",
      "bg-87",
    ],

    row_2_images: [
      "bg-88",
      "bg-89",
      "bg-90",
      "bg-91",
    ],

    row_3_images: [
      "bg-92",
      "bg-93",
      "bg-94",
      "bg-95",
    ],
    row_4_images: [
      "bg-96",
      "bg-97",
      "bg-98",
      "bg-99",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "bg-100",
      "bg-101",
      "bg-102",
      "bg-103",
    ],

    row_2_images: [
      "bg-104",
      "bg-105",
      "bg-106",
      "bg-107",
    ],

    row_3_images: [
      "bg-108",
      "bg-109",
      "bg-110",
      "bg-111",


    ],
    row_4_images: [
      "bg-112",
      "bg-113",
      "bg-114",
      "bg-115",
    ],

  },
];

export const TechStackFullStackMobile = [
  {
    id: 0,
    row_1_images: [
      "bg-68",
      "bg-69",
      "bg-70",
    ],
    
    row_2_images: [
      "bg-71",
      "bg-72",
      "bg-73",
    ],
    
    row_3_images: [
      "bg-74",
      "bg-75",
      "bg-76",
    ],
    row_4_images: [
      "bg-77",
      "bg-78",
      "bg-79",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-80",
      "bg-81",
      "bg-82",
    ],
    
    row_2_images: [
      "bg-83",
      "bg-84",
      "bg-85",
    ],
    
    row_3_images: [
      "bg-86",
      "bg-87",
      "bg-88",
      
    ],
    row_4_images: [
      "bg-89",
      "bg-90",
      "bg-91",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "bg-92",
      "bg-93",
      "bg-94",
    ],

    row_2_images: [
      "bg-95",
      "bg-96",
      "bg-97",
    ],
    
    row_3_images: [
      "bg-98",
      "bg-99",
      "bg-100",
    ],
    row_4_images: [
      "bg-101",
      "bg-102",
      "bg-103",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "bg-104",
      "bg-105",
      "bg-106",
    ],

    row_2_images: [
      "bg-107",
      "bg-108",
      "bg-109",


    ],

    row_3_images: [
      "bg-110",
      "bg-111",
      "bg-112",
    ],
    row_4_images: [
      "bg-113",
      "bg-114",
      "bg-115",
    ],
  },
];

export const TechStackDataScience = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-4",
      "bg-5",
      "bg-6",
      "bg-7",
      "bg-8",
    ],

    row_2_images: [
      "bg-9",
      "bg-10",
      "bg-11",
      "bg-12",
      "bg-13",
      "bg-14",
      "bg-15",
      "bg-16",
    ],

    row_3_images: [
      "bg-17",
      "bg-18",
      "bg-19",
      "bg-20",
      "bg-21",
      "bg-22",
      "bg-23",
      "bg-24",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-25",
      "bg-26",
      "bg-27",
      "bg-28",
      "bg-29",
      "bg-30",
      "bg-31",
      "bg-32",
    ],

    row_2_images: [
      "bg-33",
      "bg-34",
      "bg-35",
      "bg-36",
      "bg-37",
      "bg-38",
      "bg-39",
      "bg-40",
    ],

    row_3_images: [
      "bg-41",
      "bg-42",
      "bg-43",
      "bg-44",
      "bg-45",
      "bg-46",
      "bg-47",
      "bg-48",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "bg-49",
      "bg-50",
      "bg-51",
      "bg-52",
      "bg-53",
      "bg-54",
      "bg-55",
      "bg-56",
    ],
    row_2_images: [
      "bg-57",
      "bg-58",
      "bg-59",
      "bg-60",
      "bg-61",
      "bg-62",
      "bg-63",
      "bg-64",
    ],

    row_3_images: [
      "bg-65",
      "bg-66",
      "bg-67",
    ],
  },
];

export const TechStackDataScienceTablet = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-4",
    ],

    row_2_images: [
      "bg-5",
      "bg-6",
      "bg-7",
      "bg-8",
    ],

    row_3_images: [
      "bg-9",
      "bg-10",
      "bg-11",
      "bg-12",
    ],

    row_4_images: [
      "bg-13",
      "bg-14",
      "bg-15",
      "bg-16",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-17",
      "bg-18",
      "bg-19",
      "bg-20",
    ],

    row_2_images: [
      "bg-21",
      "bg-22",
      "bg-23",
      "bg-24",
    ],

    row_3_images: [
      "bg-25",
      "bg-26",
      "bg-27",
      "bg-28",
    ],
    row_4_images: [
      "bg-29",
      "bg-30",
      "bg-31",
      "bg-32",
    ],
  },

  {
    id: 2,
    row_1_images: [
     "bg-33",
      "bg-34",
      "bg-35",
      "bg-36",
    ],

    row_2_images: [
      "bg-37",
      "bg-38",
      "bg-39",
      "bg-40",
    ],

    row_3_images: [
      "bg-41",
      "bg-42",
      "bg-43",
      "bg-44",
    ],

    row_4_images: [
     "bg-45",
      "bg-46",
      "bg-47",
      "bg-48",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "bg-49",
      "bg-50",
      "bg-51",
      "bg-52",
    ],

    row_2_images: [
      "bg-53",
      "bg-54",
      "bg-55",
      "bg-56",
    ],

    row_3_images: [
      "bg-57",
      "bg-58",
      "bg-59",
      "bg-60",
    ],
    row_4_images: [
      "bg-61",
      "bg-62",
      "bg-63",
      "bg-64",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "bg-65",
      "bg-66",
      "bg-67",
    ],

    row_2_images: [],

    row_3_images: [],
    row_4_images: [],
  },
];

export const TechStackDataScienceMobile = [
  {
    id: 0,
    row_1_images: [
     "bg-1",
      "bg-2",
      "bg-3",
    ],

    row_2_images: [
      "bg-4",
      "bg-5",
      "bg-6",
    ],

    row_3_images: [
      "bg-7",
      "bg-8",
      "bg-9",
    ],
    row_4_images: [
      "bg-10",
      "bg-11",
      "bg-12",
    ],
  },

  {
    id: 1,
    row_1_images: [
     "bg-13",
      "bg-14",
      "bg-15",
    ],

    row_2_images: [
      "bg-16",
      "bg-17",
      "bg-18",
    ],

    row_3_images: [
      "bg-19",
      "bg-20",
      "bg-21",
    ],
    row_4_images: [
      "bg-22",
      "bg-23",
      "bg-24",
    ],
  },

  {
    id: 2,
    row_1_images: [
      "bg-25",
      "bg-26",
      "bg-27",
    ],

    row_2_images: [
     "bg-28",
      "bg-29",
      "bg-30",
    ],

    row_3_images: [
      "bg-31",
      "bg-32",
      "bg-33",
    ],
    row_4_images: [
      "bg-34",
      "bg-35",
      "bg-36",
    ],
  },

  {
    id: 3,
    row_1_images: [
      "bg-37",
      "bg-38",
      "bg-39",
    ],

    row_2_images: [
      "bg-40",
      "bg-41",
      "bg-42",
    ],

    row_3_images: [
      "bg-43",
      "bg-44",
      "bg-45",
    ],
    row_4_images: [
      "bg-46",
      "bg-47",
      "bg-48",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "bg-49",
      "bg-50",
      "bg-51",
    ],

    row_2_images: [
      "bg-52",
      "bg-53",
      "bg-54",
    ],

    row_3_images: [
      "bg-55",
      "bg-56",
      "bg-57",
    ],
    row_4_images: [
      "bg-58",
      "bg-59",
      "bg-60",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "bg-61",
      "bg-62",
      "bg-63",
    ],

    row_2_images: [
      "bg-64",
      "bg-65",
      "bg-66",
    ],

    row_3_images: ["bg-67"],
    row_4_images: [],
  },
];

export const TechStackDevOps = [
  {
    id: 0,
    row_1_images: [
      "bg-116",
      "bg-117",
      "bg-118",
      "bg-119",
      "bg-120",
      "bg-121",
      "bg-122",
      "bg-123",
    ],

    row_2_images: [
      "bg-124",
      "bg-125",
      "bg-126",
      "bg-127",
      "bg-128",
      "bg-129",
      "bg-130",
      "bg-131",
    ],

    row_3_images: [],
  },
];

export const TechStackDevOpsTablet = [
  {
    id: 0,
    row_1_images: [
      "bg-116",
      "bg-117",
      "bg-118",
      "bg-119",
    ],

    row_2_images: [
      "bg-120",
      "bg-121",
      "bg-122",
      "bg-123",
    ],

    row_3_images: [
      "bg-124",
      "bg-125",
      "bg-126",
      "bg-127",
    ],
    row_4_images: [
      "bg-128",
      "bg-129",
      "bg-130",
      "bg-131",
    ],
  },
];

export const TechStackDevOpsMobile = [
  {
    id: 0,
    row_1_images: [
      "bg-116",
      "bg-117",
      "bg-118",
    ],

    row_2_images: [
      "bg-119",
      "bg-120",
      "bg-121",
    ],

    row_3_images: [
      "bg-122",
      "bg-123",
      "bg-124",
    ],
    row_4_images: [
     "bg-125",
      "bg-126",
      "bg-127",
    ],
  },

  {
    id: 1,
    row_1_images: [
      "bg-128",
      "bg-129",
      "bg-130",
    ],

    row_2_images: [
      "bg-131",
    ],

    row_3_images: [],
    row_4_images: [],
  },
];

export const Awards = [
  {
    id: 0,
    row_1_images: [
      "bg-1", "bg-2", "bg-3", "bg-4", "bg-5", "bg-6"
    ],
    row_2_images: [
      "bg-7", "bg-8", "bg-9", "bg-10", "bg-11"
    ],
  },
  {
    id: 1,
    row_1_images: [
      "bg-12", "bg-13", "bg-14", "bg-15", "bg-16", "bg-17"
    ],
    row_2_images: [
      "bg-18", "bg-19", "bg-20", "bg-21", "bg-22"
    ],
  },
  {
    id: 2,
    row_1_images: [
      "bg-23", "bg-24", "bg-25", "bg-26", "bg-27", "bg-28"
    ],
    row_2_images: [
      "bg-29", "bg-30", "bg-31", "bg-32", "bg-33"
    ],
  },
  {
    id: 3,
    row_1_images: [
      "bg-34", "bg-35", "bg-36", "bg-37", "bg-38", "bg-39"
    ],
    row_2_images: [
      "bg-40", "bg-41", "bg-42", "bg-43", "bg-44"
    ],
  },
  {
    id: 4,
    row_1_images: [
      "bg-45", "bg-46", "bg-47", "bg-48", "bg-49", "bg-50"
    ],
    row_2_images: [
      "bg-51", "bg-52", "bg-53", "bg-54", "bg-55"
    ],
  },
  {
    id: 5,
    row_1_images: [
      "bg-56", "bg-57", "bg-58", "bg-59"
    ],
    row_2_images: [],
  },
];

export const AwardsTablet = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
      "bg-6",
    ],

    row_2_images: [
      "bg-5",
      "bg-4",
      "bg-9",
    ],
  },
  {
    id: 1,
    row_1_images: [
      "bg-7",
      "bg-8",
      "bg-10",
      "bg-11",
    ],

    row_2_images: [
      "bg-12",
      "bg-13",
      "bg-14",
    ],
  },
  {
    id: 2,
    row_1_images: [
      "bg-15",
      "bg-16",
      "bg-17",
      "bg-18",
    ],

    row_2_images: [
      "bg-19",
      "bg-20",
      "bg-21",
    ],
  },
  {
    id: 3,
    row_1_images: [
      "bg-22",
      "bg-23",
      "bg-24",
      "bg-25",
    ],

    row_2_images: [
      "bg-26",
      "bg-27",
      "bg-28",
    ],
  },

  {
    id: 4,
    row_1_images: [
      "bg-29",
      "bg-30",
      "bg-31",
      "bg-32",
    ],

    row_2_images: [
      "bg-33",
      "bg-34",
      "bg-35",
    ],
  },

  {
    id: 5,
    row_1_images: [
      "bg-36",
      "bg-37",
      "bg-38",
      "bg-39",
    ],

    row_2_images: [
      "bg-40",
      "bg-41",
      "bg-42",
    ],
  },

  {
    id: 6,
    row_1_images: [
      "bg-43",
      "bg-44",
      "bg-45",
      "bg-46",
    ],

    row_2_images: [
      "bg-47",
      "bg-48",
      "bg-49",
    ],
  },

  {
    id: 7,
    row_1_images: [
      "bg-50",
      "bg-51",
      "bg-52",
      "bg-53",
    ],

    row_2_images: [
      "bg-54",
      "bg-55",
      "bg-56",
    ],
  },

  {
    id: 8,
    row_1_images: [
      "bg-57",
      "bg-58",
      "bg-59",
    ],

    row_2_images: [],
  },
];

export const AwardsMobile = [
  {
    id: 0,
    row_1_images: [
      "bg-1",
      "bg-2",
      "bg-3",
    ],

    row_2_images: [
      "bg-4", 
      "bg-6"
    ],
  },
  {
    id: 1,
    row_1_images: [
      "bg-7",
      "bg-5",
      "bg-8",
    ],

    row_2_images: [
      "bg-10",
       "bg-11"
      ],
  },
  {
    id: 2,
    row_1_images: [
      "bg-9",
      "bg-12",
      "bg-13",
    ],

    row_2_images: [
      "bg-14",
       "bg-15"
      ],
  },
  {
    id: 3,
    row_1_images: [
      "bg-16",
      "bg-17",
      "bg-18",
    ],

    row_2_images: ["bg-19", "bg-20", ],
  },

  {
    id: 4,
    row_1_images: [
      "bg-21",
      "bg-22",
      "bg-23",
    ],

    row_2_images: ["bg-24", "bg-25"],
  },

  {
    id: 5,
    row_1_images: [
      "bg-26",
      "bg-27",
      "bg-28",
    ],

    row_2_images: ["bg-29", "bg-30"],
  },

  {
    id: 6,
    row_1_images: [
      "bg-31",
      "bg-32",
      "bg-33",
    ],

    row_2_images: ["bg-34", "bg-35"],
  },

  {
    id: 7,
    row_1_images: [
      "bg-36",
      "bg-37",
      "bg-38",
    ],

    row_2_images: ["bg-39", "bg-40"],
  },

  {
    id: 8,
    row_1_images: [
      "bg-41",
      "bg-42",
      "bg-43",
    ],

    row_2_images: ["bg-44", "bg-45"],
  },
  {
    id: 9,
    row_1_images: [
      "bg-46",
      "bg-47",
      "bg-48",
    ],

    row_2_images: ["bg-49", "bg-50"],
  },
  {
    id: 10,
    row_1_images: [
      "bg-51",
      "bg-52",
      "bg-53",
    ],

    row_2_images: ["bg-54", "bg-55"],
  },
  {
    id: 11,
    row_1_images: [
      "bg-56",
      "bg-57",
      "bg-58",
    ],

    row_2_images: ["bg-59"],
  },
];

export const clienteleData = [
  {
    id: 0,
    row_1_images: [
      'bg-1',
      'bg-2',
      'bg-3',
      'bg-4'
    ],
    row_2_images: [
      'bg-5',
      'bg-6',
      'bg-7',
      'bg-8'
    ]
  },
  {
    id: 1,
    row_1_images: [
      'bg-9',
      'bg-10',
      'bg-11',
      'bg-12'
    ],
    row_2_images: [
      'bg-13',
      'bg-14',
      'bg-15',
      'bg-16'
    ]
  },
  {
    id: 2,
    row_1_images: [
      'bg-17',
      'bg-18',
      'bg-19',
      'bg-20'
    ],
    row_2_images: [
      'bg-21',
      'bg-22',
      'bg-23',
      'bg-24'
    ]
  },
  {
    id: 3,
    row_1_images: [
      'bg-25',
      'bg-26',
      'bg-27',
      'bg-28'
    ],
    row_2_images: [
      'bg-29',
      'bg-30',
      'bg-31',
      'bg-32'
    ]
  },
  {
    id: 4,
    row_1_images: [
      'bg-33',
      'bg-34',
      'bg-35',
      'bg-36'
    ],
    row_2_images: [
      'bg-37',
      'bg-38',
      'bg-39',
      'bg-40'
    ]
  }
];

export const clienteleTabletData = [
  {
    id: 0,
    row_1_images: [
      'bg-1',
      'bg-2',
      'bg-3',

    ],
    row_2_images: [
      'bg-4',
      'bg-5',
      'bg-6',

    ],
  },
  {
    id: 1,
    row_1_images: [
      'bg-7',
      'bg-8',
      'bg-9',
    ],
    row_2_images: [
      'bg-10',
      'bg-11',
      'bg-12'
    ],
  },
  {
    id: 2,
    row_1_images: [
      'bg-13',
      'bg-14',
      'bg-15',
    ],
    row_2_images: [
      'bg-16',
      'bg-17',
      'bg-18',

    ],
  },
  {
    id: 3,
    row_1_images: [
      'bg-19',
      'bg-20',
      'bg-21',
    ],
    row_2_images: [
      'bg-22',
      'bg-23',
      'bg-24'
    ],
  },
  {
    id: 4,
    row_1_images: [
      'bg-25',
      'bg-26',
      'bg-27',
    ],
    row_2_images: [
      'bg-28',
      'bg-29',
      'bg-30',

    ],
  },
  {
    id: 5,
    row_1_images: [
      'bg-31',
      'bg-32',
      'bg-33',

    ],
    row_2_images: [
      'bg-34',
      'bg-35',
      'bg-36'

    ],
  },
  {
    id: 6,
    row_1_images: [
      'bg-37',
      'bg-38',
      'bg-39',

    ],
    row_2_images: [
      'bg-40',
    ],
  },
];

export const clienteleMobileData = [
  {
    id: 0,
    row_1_images: [
      'bg-1',
      'bg-2',

    ],
    row_2_images: [
      'bg-3',
      'bg-4',

    ],
  },
  {
    id: 1,
    row_1_images: [
      'bg-5',
      'bg-6',
    ],
    row_2_images: [
      'bg-7',
      'bg-8',
    ],
  },
  {
    id: 2,
    row_1_images: [
      'bg-9',
      'bg-10',
    ],
    row_2_images: [
      'bg-11',
      'bg-12',

    ],
  },
  {
    id: 3,
    row_1_images: [
      'bg-13',
      'bg-14',
    ],
    row_2_images: [
      'bg-15',
      'bg-16',
    ],
  },
  {
    id: 4,
    row_1_images: [
      'bg-17',
      'bg-18',
    ],
    row_2_images: [
      'bg-19',
      'bg-20',

    ],
  },
  {
    id: 5,
    row_1_images: [
      'bg-21',
      'bg-22',

    ],
    row_2_images: [
      'bg-23',
      'bg-24',

    ],
  },
  {
    id: 6,
    row_1_images: [
      'bg-25',
      'bg-26',

    ],
    row_2_images: [
      'bg-27',
      'bg-28',

    ],
  },
  {
    id: 7,
    row_1_images: [
      'bg-29',
      'bg-30',
    ],
    row_2_images: [
      'bg-31',
      'bg-32',

    ],
  },
  {
    id: 8,
    row_1_images: [
      'bg-33',
      'bg-34',

    ],
    row_2_images: [
      'bg-35',
      'bg-36',
    ],
  },
  {
    id: 9,
    row_1_images: [
      'bg-37',
      'bg-38',


    ],
    row_2_images: [
      'bg-39',
      'bg-40',
    ],
  },
];



export const testimonialData = [
  {
    quote: "testimonial.quote1",
    text: "testimonial.text1",
    logo: "bg-1",
    location: "testimonial.location1",
    industry: "testimonial.industry1",
  },
  {
    quote: "testimonial.quote2",
    text: "testimonial.text2",
    logo: "bg-2",
    location: "testimonial.location2",
    industry: "testimonial.industry2",
  },
  {
    quote: "testimonial.quote3",
    text: "testimonial.text3",
    logo: "bg-3",
    location: "testimonial.location3",
    industry: "testimonial.industry3",
  },
  {
    quote: "testimonial.quote4",
    text: "testimonial.text4",
    logo: "bg-4",
    location: "testimonial.location4",
    industry: "testimonial.industry4",
  },
  {
    quote: "testimonial.quote5",
    text: "testimonial.text5",
    logo: "bg-5",
    location: "testimonial.location5",
    industry: "testimonial.industry5",
  },
  {
    quote: "testimonial.quote6",
    text: "testimonial.text6",
    logo: "bg-6",
    location: "testimonial.location6",
    industry: "testimonial.industry6",
  },
  {
    quote: "testimonial.quote7",
    text: "testimonial.text7",
    logo: "bg-7",
    location: "testimonial.location7",
    industry: "testimonial.industry7",
  },
  {
    quote: "testimonial.quote8",
    text: "testimonial.text8",
    logo: "bg-8",
    location: "testimonial.location8",
    industry: "testimonial.industry8",
  },
  {
    quote: "testimonial.quote9",
    text: "testimonial.text9",
    logo: "bg-9",
    location: "testimonial.location9",
    industry: "testimonial.industry9",
  },
  {
    quote: "testimonial.quote10",
    text: "testimonial.text10",
    logo: "bg-10",
    location: "testimonial.location10",
    industry: "testimonial.industry10",
  },
  {
    quote: "testimonial.quote11",
    text: "testimonial.text11",
    logo: "bg-11",
    location: "testimonial.location11",
    industry: "testimonial.industry11",
  },
  {
    quote: "testimonial.quote12",
    text: "testimonial.text12",
    logo: "bg-12",
    location: "testimonial.location12",
    industry: "testimonial.industry12",
  },
  {
    quote: "testimonial.quote13",
    text: "testimonial.text13",
    logo: "bg-13",
    location: "testimonial.location13",
    industry: "testimonial.industry13",
  },
  {
    quote: "testimonial.quote14",
    text: "testimonial.text14",
    logo: "bg-14",
    location: "testimonial.location14",
    industry: "testimonial.industry14",
  },
  {
    quote: "testimonial.quote15",
    text: "testimonial.text15",
    logo: "bg-15",
    location: "testimonial.location15",
    industry: "testimonial.industry15",
  },
];
export const achivementsData = [
  {
    text: "achivements.text1",
    btext: "achivements.btext1",
    img: "bg-1",
  },
  {
    text: "achivements.text2",
    btext: "achivements.btext2",
    img: "bg-2",
  },
  {
    text: "achivements.text3",
    btext: "achivements.btext3",
    img: "bg-3",
  },
  {
    text: "achivements.text4",
    btext: "achivements.btext4",
    img: "bg-4",
  },
  {
    text: "achivements.text5",
    btext: "achivements.btext5",
    img: "bg-5",
  },
  {
    text: "achivements.text6",
    btext: "achivements.btext6",
    img: "bg-6",
  },
  {
    text: "achivements.text7",
    btext: "achivements.btext7",
    img: "bg-6",
  },
];
export const BusinessVerticalsCards = [
  {
    title: "industries.title1",
    description: "industries.description1",
    image: "../images/business-verticals/hc.gif",
  },
  {
    title: "industries.title2",
    description: "industries.description2",
    image: "../images/business-verticals/re.gif",
  },
  {
    title: "industries.title3",
    description: "industries.description3",
    image: "../images/business-verticals/Game.gif",
  },
  {
    title: "industries.title4",
    description: "industries.description4",
    image: "../images/business-verticals/ft.gif",
  },
  {
    title: "industries.title5",
    description: "industries.description5",
    image: "../images/business-verticals/law.gif",
  },
  {
    title: "industries.title6",
    description: "industries.description6",
    image: "../images/business-verticals/sm.gif",
  },
  {
    title: "industries.title7",
    description: "industries.description7",
    image: "../images/business-verticals/cs.gif",
  },
  {
    title: "industries.title8",
    description: "industries.description8",
    image: "../images/business-verticals/cd.gif",
  },
];
export const BusinessVerticalsTabletCards = [
  {
    row_1_images: [
      {
        title: "industries.title1",
        description: "industries.description1",
        image: "../images/business-verticals/Frame_1000002780.webp",
      },
      {
        title: "industries.title2",
        description: "industries.description2",
        image: "../images/business-verticals/Frame_1000002780-1.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title3",
        description: "industries.description3",
        image: "../images/business-verticals/Frame_1000002780-2.webp",
      },
      {
        title: "industries.title4",
        description: "industries.description4",
        image: "../images/business-verticals/Frame_1000002780-3.webp",
      },
    ],
  },
  {
    row_1_images: [
      {
        title: "industries.title5",
        description: "industries.description5",
        image: "../images/business-verticals/Frame_1000002780-4.webp",
      },
      {
        title: "industries.title6",
        description: "industries.description6",
        image: "../images/business-verticals/Frame_1000002780-5.webp",
      },
    ],
    row_2_images: [
      {
        title: "industries.title7",
        description: "industries.description7",
        image: "../images/business-verticals/Frame_1000002780-6.webp",
      },
      {
        title: "industries.title8",
        description: "industries.description8",
        image: "../images/business-verticals/Frame_1000002780-7.webp",
      },
    ],
  },
];

export const AreaExperticsData = [
  {
    id: 1,
    title: "areaexpertise.AI",
    cards: [
      {
        title: "areaexpertise.ai1",
        image: "bg-1",
      },
      {
        title: "areaexpertise.ai2",
        image: "bg-2",
      },
      {
        title: "areaexpertise.ai3",
        image: "bg-3",
      },
      {
        title: "areaexpertise.ai4",
        image: "bg-4",
      },
      {
        title: "areaexpertise.ai5",
        image: "bg-5",
      },
      {
        title: "areaexpertise.ai6",
        image: "bg-6",
      },
    ],
  },
  {
    id: 2,
    title: "areaexpertise.GenAI",
    cards: [
      {
        title: "areaexpertise.genai1",
        image: "bg-7",
      },
      {
        title: "areaexpertise.genai2",
        image: "bg-8",
      },
      {
        title: "areaexpertise.genai3",
        image: "bg-9",
      },
      {
        title: "areaexpertise.genai4",
        image: "bg-10",
      },
      {
        title: "areaexpertise.genai5",
        image: "bg-11",
      },
      {
        title: "areaexpertise.genai6",
        image: "bg-12",
      },
    ],
  },
  {
    id: 3,
    title: "areaexpertise.Development",
    cards: [
      {
        title: "areaexpertise.dev1",
        image: "bg-13",
      },
      {
        title: "areaexpertise.dev2",
        image: "bg-14",
      },
      {
        title: "areaexpertise.dev3",
        image: "bg-15",
      },
      {
        title: "areaexpertise.dev4",
        image: "bg-16",
      },
      {
        title: "areaexpertise.dev5",
        image: "bg-17",
      },
      {
        title: "areaexpertise.dev6",
        image: "bg-18",
      },
    ],
  },
  {
    id: 4,
    title: "areaexpertise.Deployment",
    cards: [
      {
        title: "areaexpertise.dep1",
        image: "bg-19",
      },
      {
        title: "areaexpertise.dep2",
        image: "bg-20",
      },
      {
        title: "areaexpertise.dep3",
        image: "bg-21",
      },
      {
        title: "areaexpertise.dep4",
        image: "bg-22",
      },
      {
        title: "areaexpertise.dep5",
        image: "bg-23",
      },
      {
        title: "areaexpertise.dep6",
        image: "bg-24",
      },
    ],
  },
];

export const productsData = [
  {
    id: 1,
    title: "products.product1_title",
    sub_title: "products.product1_subtitle",
    description_1: "products.product1_desc1",
    description_2: "products.product1_desc2",
    product_image: "../images/products/Haveto_Logo.webp",
    explore_url: "https://haveto.com/",
  },
  {
    id: 2,
    title: "products.product3_title",
    sub_title: "products.product3_subtitle",
    description_1: "products.product3_desc1",
    description_2: "products.product3_desc2",
    product_image: "../images/products/FxchatLogo.webp",
    explore_url: "https://fxchat.htree.plus/",
  },
  {
    id: 3,
    title: "products.product2_title",
    sub_title: "products.product2_subtitle",
    description_1: "products.product2_desc1",
    description_2: "products.product2_desc2",
    product_image: "../images/products/AI_Coder.webp",
  },
  {
    id: 4,
    title: "products.product4_title",
    sub_title: "products.product4_subtitle",
    description_1: "products.product4_desc1",
    description_2: "products.product4_desc2",
    product_image: "../images/products/AITutor.webp",
    explore_url: "https://aitutor.htree.plus/",
  },
];
export const servicesData = [
  {
    id: 0,
    cardTitle: "services.card1",
    cardDesc: "services.desc1",
    // modalTitleId: "project-outsourcing-modal-title-vcenter",
    // modalTitle: "projectoutsourcing.title",
    // modalComponent: <ProjectOutsourcing />,
  },
  {
    id: 1,
    cardTitle: "services.card2",
    cardDesc: "services.desc2",
    // modalTitleId: "on-demand-developer-modal-title-vcenter",
    // modalTitle: "ondemanddev.title",
    // modalComponent: <OnDemandDe />,
  },
  {
    id: 2,
    cardTitle: "services.card3",
    cardDesc: "services.desc3",
    // modalTitleId: "tech-and-talent-modal-title-vcenter",
    // modalTitle: "techandtalent.title",
    // modalComponent: <TechandTalent />,
  },
];

export const successimage = {
  image: "../images/success_image.webp",
};

export const navbarheaderlinks = [
  {
    id: 0,
    title: "navbarlinks.title1",
    links: [
      {
        link_title: "navbarlinks.title1_link1",
        link_id: "clientele",
      },
      {
        link_title: "navbarlinks.title1_link2",
        link_id: "hire-ex-experts",
      },
      {
        link_title: "navbarlinks.title1_link3",
        link_id: "testimonial",
      },
    ],
  },
  {
    id: 1,
    title: "navbarlinks.title2",
    links: [
      {
        link_title: "navbarlinks.title2_link1",
        link_id: "techstack",
      },
      {
        link_title: "navbarlinks.title2_link2",
        link_id: "areaexpertise",
      },
    ],
  },
  {
    id: 2,
    title: "navbarlinks.title3",
    links: [
      {
        link_title: "navbarlinks.title3_link1",
        link_id: "gwr",
      },
      {
        link_title: "navbarlinks.title3_link2",
        link_id: "awards",
      },
    ],
  },
  {
    id: 3,
    title: "navbarlinks.title4",
    links: [
      {
        link_title: "navbarlinks.title4_link1",
        link_id: "services",
      },
      {
        link_title: "navbarlinks.title4_link2",
        link_id: "industry",
      },
    ],
  },
  {
    id: 4,
    title: "navbarlinks.title5",
    links: [
      {
        link_title: "navbarlinks.title5_link1",
        link_id: "product-1",
      },
      {
        link_title: "navbarlinks.title5_link2",
        link_id: "product-2",
      },
      {
        link_title: "navbarlinks.title5_link3",
        link_id: "product-3",
      },
      {
        link_title: "navbarlinks.title5_link4",
        link_id: "product-4",
      },
    ],
  },
  // {
  //   id: 5,
  //   title: "navbarlinks.title6",
  //   links: [
  //     {
  //       link_title: "navbarlinks.title6_link1",
  //       link_id: "/",
  //     },
  //     {
  //       link_title: "navbarlinks.title6_link2",
  //       link_id: "/",
  //     },
  //   ],
  // },
  {
    id: 6,
    title: "navbarlinks.title7",
    title_link: "https://fxis.ai/edu/",
  },
  {
    id: 7,
    title: "navbarlinks.title8",
    title_link: "contact",
  },
];

export const OurFoundersData = [
  {
    image: "../images/OurFounders/founder1.webp",
    desc: "ourfounders.desc1",
    name: "ourfounders.name1",
    info: "ourfounders.info1",
    social_media: [
      { icon: "../images/OurFounders/linkedin.webp", link: "https://www.linkedin.com/in/hemenashodia/" },
      { icon: "../images/OurFounders/instagram.webp", link: "https://www.instagram.com/he_men/?igsh=dmp2enpyeWxvbzVi" }
    ],
    open_quote: "../images/OurFounders/open_quote.webp",
    close_quote: "../images/OurFounders/close_quote.webp"
  },
  {
    image: "../images/OurFounders/founder2.webp",
    desc: "ourfounders.desc2",
    name: "ourfounders.name2",
    info: "ourfounders.info2",
    social_media: [
      { icon: "../images/OurFounders/linkedin.webp", link: "https://www.linkedin.com/in/pavan-b-3153971a6/" },
      { icon: "../images/OurFounders/bakery.webp", link: "https://bakeri.com/" }
    ],
    open_quote: "../images/OurFounders/open_quote.webp",
    close_quote: "../images/OurFounders/close_quote.webp"
  },
];