
export const LOG_IN_REQ = "LOG_IN_REQ";
export const LOG_IN = "LOG_IN";
export const LOG_IN_ERR = "LOG_IN_ERR";
export const LOG_OUT = "LOG_OUT";
export const VERIFY_ACC = "VERIFY_ACC";
export const RESEND_TOKEN = "RESEND_TOKEN";
export const VERIFY_ACC_ERR = "VERIFY_ACC_ERR";
export const TOGGLE_AUTH_LOADER = "TOGGLE_AUTH_LOADER";
export const REGISTER_REQ = "REGISTER_REQ";
export const REGISTER = "REGISTER";
export const REGISTER_ERR = "REGISTER_ERR";
export const RESET_AUTH_MESSAGES_AND_ERRORS = "RESET_AUTH_MESSAGES_AND_ERRORS";

export const INCREMENT = "INCREMENT";
export const DECREMENT = "DECREMENT";
export const RESET = "RESET";

export const HIRE_NOW_DATA = "HIRE_NOW_DATA";
export const HIRE_NOW_REGISTER_REQ = "HIRE_NOW_REGISTER_REQ";
export const HIRE_NOW_REGISTER = "HIRE_NOW_REGISTER";
export const HIRE_NOW_REGISTER_ERR = "HIRE_NOW_REGISTER_ERR";

export const REQ_DATA = 'REQ_DATA';
export const TECH_DATA = 'TECH_DATA';

export const CHECK_LOAD_JQUERY = "CHECK_LOAD_JQUERY";
export const CHECK_LOAD_JAVASCRIPT = "CHECK_LOAD_JAVASCRIPT";

export const GET_DEVELOPERS_REQ = "GET_DEVELOPERS_REQ";
export const GET_DEVELOPERS = "GET_DEVELOPERS";
export const UPDATE_DEV_STATUS_REQ = "UPDATE_DEV_STATUS_REQ";
export const UPDATE_DEV_STATUS_SUC = "UPDATE_DEV_STATUS_SUC";
export const GET_RECRUITMENT_PHASE_REQ = "GET_RECRUITMENT_PHASE_REQ";
export const GET_RECRUITMENT_PHASE_SUC = "GET_RECRUITMENT_PHASE_SUC";
export const TOGGLE_DEVELOPER_MODALS = "TOGGLE_DEVELOPER_MODALS";
export const CHANGE_STATUS_FILTER_DATA = "SET_DEVELOPER_FILTER_DATA";
export const CHANGE_PHASE_FILTER_DATA = "CHANGE_PHASE_FILTER_DATA";
export const CHANGE_SEARCH_TERM_FILTER_DATA = "CHANGE_SEARCH_TERM_FILTER_DATA";

export const GET_DEVELOPERS_JOB_TITLE_LIST_REQ =
    "GET_DEVELOPERS_JOB_TITLE_LIST_REQ";
export const GET_DEVELOPERS_JOB_TITLE_LIST = "GET_DEVELOPERS_JOB_TITLE_LIST";
export const GET_DEVELOPERS_JOB_TITLE_LIST_ERR =
    "GET_DEVELOPERS_JOB_TITLE_LIST_ERR";

export const GET_DEVELOPER_APPLICATION_STATUS_REQ =
    "GET_DEVELOPER_APPLICATION_STATUS_REQ";
export const GET_DEVELOPER_APPLICATION_STATUS =
    "GET_DEVELOPER_APPLICATION_STATUS";
