import {
    GET_DEVELOPERS, GET_DEVELOPERS_JOB_TITLE_LIST, GET_DEVELOPERS_JOB_TITLE_LIST_ERR, GET_DEVELOPERS_JOB_TITLE_LIST_REQ,
    GET_DEVELOPERS_REQ,
    GET_RECRUITMENT_PHASE_REQ, GET_RECRUITMENT_PHASE_SUC,
    CHANGE_STATUS_FILTER_DATA, TOGGLE_DEVELOPER_MODALS,
    UPDATE_DEV_STATUS_REQ,
    UPDATE_DEV_STATUS_SUC, CHANGE_SEARCH_TERM_FILTER_DATA, CHANGE_PHASE_FILTER_DATA
} from "../actions/actionTypes";
import {getPaginationPages} from "../../utils/getPaginationPages";

const initState = {
    mainLoader: true,
    updateLoader: {
        accept: 0,
        reject: 0,
        onBoarding: 0,
    },
    showModal: {
        reject: 0,
        onBoarding: 0,
    },
    getJobTitleListLoader: false,
    getRecruitmentPhaseLoader: false,
    developers: [],
    recruitmentPhases: [],
    filteredDevelopers: [],
    application_status_loader: true,
    application_status_data: [],
    jobTitles: [],
    secondLastRecruitmentPhase: null,
    paginationData: null,
    filterData: {
        searchTerm: '',
        filters: {
            statusFilter: [2],
            phaseFilter: [],
        }
    }
}

const DevelopersHandler = (state = initState, action) => {
    switch (action.type) {
        case GET_DEVELOPERS_REQ:
            return {
                ...state,
                mainLoader: action.payload
            }
        case GET_DEVELOPERS:
            let developers = action.payload.data;
            delete action.payload.data;
            let pages = getPaginationPages(action.payload.current_page, action.payload.last_page)
            return {
                ...state,
                developers: developers,
                paginationData: {
                    ...action.payload,
                    pages: pages
                },
                filteredDevelopers: developers,
                mainLoader: false
            }
        case UPDATE_DEV_STATUS_REQ:
            let {type, value} = action.payload;
            return {
                ...state,
                updateLoader: {
                    ...state.updateLoader,
                    [type]: value
                }
            }
        case UPDATE_DEV_STATUS_SUC:
            const newDeveloper = action.payload;
            let newAllDevs = state.developers;
            let newFilteredDevs = state.filteredDevelopers;
            for (let i = 0, len = newFilteredDevs.length; i < len; i++) {
                if (newFilteredDevs[i].id === newDeveloper.id) {
                    newFilteredDevs[i] = newDeveloper;
                }
            }
            for (let i = 0, len = newAllDevs.length; i < len; i++) {
                if (newAllDevs[i].id === newDeveloper.id) {
                    newAllDevs[i] = newDeveloper;
                }
            }
            return {
                ...state,
                updateLoader: {
                    ...state.updateLoader,
                    [action.loaderType]: 0,
                },
                developers: newAllDevs,
                filteredDevelopers: newFilteredDevs,
                showModal: {
                    ...state.showModal,
                    [action.loaderType]: false
                }
            }
        case GET_RECRUITMENT_PHASE_REQ: {
            return {
                ...state,
                getRecruitmentPhaseLoader: true
            }
        }
        case GET_RECRUITMENT_PHASE_SUC: {
            return {
                ...state,
                getRecruitmentPhaseLoader: false,
                recruitmentPhases: action.payload.data,
                secondLastRecruitmentPhase: action.payload.secondLastPhase,
            }
        }
        case TOGGLE_DEVELOPER_MODALS:
            return {
                ...state,
                showModal: {
                    ...state.showModal,
                    [action.payload.type]: action.payload.id
                },
            }
        case GET_DEVELOPERS_JOB_TITLE_LIST_REQ:
            return {
                ...state,
                getJobTitleListLoader: true
            }
        case GET_DEVELOPERS_JOB_TITLE_LIST:
            return {
                ...state,
                getJobTitleListLoader: false,
                jobTitles: action.payload
            }
        case GET_DEVELOPERS_JOB_TITLE_LIST_ERR:
            return {
                ...state,
                getJobTitleListLoader: false,
                jobTitles: []
            }
        case CHANGE_STATUS_FILTER_DATA:
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    filters: {
                        ...state.filterData.filters,
                        statusFilter: action.payload
                    }
                }
            }
        case CHANGE_PHASE_FILTER_DATA:
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    filters: {
                        ...state.filterData.filters,
                        phaseFilter: action.payload
                    }
                }
            }
        case CHANGE_SEARCH_TERM_FILTER_DATA:
            return {
                ...state,
                filterData: {
                    ...state.filterData,
                    searchTerm: action.payload
                }
            }
        default:
            return state;
    }
}

export default DevelopersHandler;
