import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { successimage } from "../../utils/constants";
import "./successmodal.css";
function SuceesModal({ SuccessModalVisible, onclose }) {
  const { t } = useTranslation();
  return (
    <Modal
      show={SuccessModalVisible}
      onHide={onclose}
      centered
      dialogClassName="custom-modal-dialog"
    >
      <Modal.Header closeButton className="border-0"></Modal.Header>
      <Modal.Body style={{ padding: "0 24px 20px 24px" }}>
        <div className="success-modal-container">
          <div>
            <img src={successimage.image} alt=""/>
          </div>
          <h2 className="success-tag">Success!</h2>
          <div className="success-msg">
            {t("connectnowmodel.wewillconnectsoon")}
          </div>
          <button
            type="submit"
            className="btn whatsapp_phonenumber_submit_btn modalBtn success-modal-submitbtn"
            onClick={onclose}
          >
            done
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default SuceesModal;
