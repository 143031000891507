import React from "react";
import DeveloperRegistrationForm from "../../Components/Auth/DeveloperRegistration/DeveloperRegistrationForm"


export default function Hirenow() {
    return (
        <React.Fragment>
            <div style={{paddingTop:"140px"}}  className="container-applynow">
                <div className="col-md-6 mt-0" id="carrer-img">
                    <img
                        src="images/frame-carrer.svg"
                        className="carrer-img"
                        alt="img"
                    />
                </div>
                <div className="col-md-6" id="form-div-main">
                    <DeveloperRegistrationForm />
                </div>
            </div>
        </React.Fragment>
    );
}
