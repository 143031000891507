import { combineReducers } from 'redux';
import sessionHandler from './sessionHandler';
import javascriptHandler from './javascriptLoadingHandler';
import developersHandler from './developersHandler';
import applicationStatusHandler from './applicationStatusHandler'

const combiner = {
    sessionHandler : sessionHandler,
    javascriptHandler : javascriptHandler,
    developersHandler: developersHandler,
    applicationStatusHandler : applicationStatusHandler
}

const reducerFactory = combineReducers(combiner);

export default reducerFactory;
