export const setStoreData = (key, value, isString = false) => {
    try {
        if (isString) {
            localStorage.setItem(key, value);
        } else {
            localStorage.setItem(key, JSON.stringify(value));
        }
    } catch (error) {
        console.log(error);
    }
};

const getStorageData = (key) => {
    return JSON.parse(localStorage.getItem(key));
};

export const getLoggedInUserData = () => {
    return getStorageData("user");
};

export const logout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("tabs");
};
