import timezones from '../locales/countries_with_timeZones.json';

const timezoneToCountry = timezones.reduce((acc, { TimeZones, IsoAlpha2 }) => {
  TimeZones.forEach(timezone => {
    acc[timezone] = IsoAlpha2;
  });
  return acc;
}, {});

export function getCountryByTimeZone(userTimeZone) {
  return timezoneToCountry[userTimeZone] || 'US';
}