import { CHECK_LOAD_JQUERY, CHECK_LOAD_JAVASCRIPT } from  './actionTypes';

export const loadJQuery = (dispatcher) => {
    return {
        type : CHECK_LOAD_JQUERY,
        dispatcher : dispatcher
    }
}

export const loadJavascript = (dispatcher) => {
    return {
        type : CHECK_LOAD_JAVASCRIPT,
        dispatcher : dispatcher
    }
}