import React from "react";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";
import { useTranslation } from "react-i18next";
import { getCountryByTimeZone } from "../../utils/getCountryByTimezone";

const WhatsappPhoneNumberForm = ({
  phoneNumber,
  setPhoneNumber,
  error,
  inputProps,
  countrySelectorStyleProps,
}) => {
  const { t } = useTranslation();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const userCountry = getCountryByTimeZone(userTimeZone);

  return (
    <div className="form-group" style={{ width: "100%" }}>
      <label htmlFor="whatsapp_phone_number_field" className="form-label">
        {t("connectnowmodel.phonenumber")}
      </label>
      <PhoneInput
        value={phoneNumber}
        defaultCountry={userCountry.toLowerCase()}
        onChange={setPhoneNumber}
        forceDialCode={true}
        inputProps={{
          style: {
            width: "100%",
            fontSize: "16px",
            ...inputProps,
          },
        }}
        countrySelectorStyleProps={countrySelectorStyleProps}
      />
      {error && <small className="form-text text-danger">{error}</small>}
    </div>
  );
};

export default WhatsappPhoneNumberForm;
