import React from "react";
import { useTranslation } from "react-i18next";
import scriptLoader from "../../../utils/javascriptsLoader";
import "react-phone-number-input/style.css";
import ReactGA from "react-ga4";
import ConnectNowModel from "../../whatsappBot/ConnectNowModel";
import style from "../../style/home.module.css";
import "./Home.css"

class Home extends React.Component {
  loadMainScript = () => {
    this.loadLazyJavaScript();
    this.loadScript();
    this.loadSiteTrackingSripts();
  };

  loadScript() {
    const scriptParam = {
      id: "usageScriptId",
      src:
        process.env.PUBLIC_URL +
        "/js/modules/script.js?ca93809a-cd54-40d0-ad2c-a763aba41894",
      async: 1,
      defer: 1,
    };
    scriptLoader(scriptParam);
  }

  loadSiteTrackingSripts = () => {
    ReactGA.initialize("G-TPKZRWHSGV");

    ReactGA.send({ hitType: "pageview", page: "/", title: "Home Page View" });

    import("react-hotjar").then((reactHotjar) => {
      reactHotjar.hotjar.initialize(1636206, 6);
    });
  };

  componentDidMount() {
    this.loadMainScript();
    window.addEventListener("resize", this.setCurrentScreenWidth);
  }

  setCurrentScreenWidth = () => {
    let tempWidth = window.innerWidth;
    if (
      (tempWidth > 991 && this.state.currentScreenWidth <= 991) ||
      (tempWidth <= 991 && this.state.currentScreenWidth > 991)
    ) {
      this.setState({
        currentScreenWidth: tempWidth,
      });
    }
  };

  getCurrentScreenWidth = () => {
    return window.innerWidth;
  };

  loadLazyJavaScript = () => {
    if (!document.getElementById("lazyLoadScriptId")) {
      const script = {
        src:
          process.env.REACT_APP_BASE_URL +
          "js/lazyload.min.js?ca93809a-cd54-40d0-ad2c-a763aba41894",
        id: "lazyLoadScriptId",
        async: 1,
        defer: 1,
      };
      scriptLoader(script, () => {
        if (window.lazyLoadInstance) {
          window.lazyLoadInstance.update();
        }
      });
    }
  };

  state = {
    currentScreenWidth: this.getCurrentScreenWidth(),
  };

  mobileWidth = this.state.currentScreenWidth >= 500;
  destopWidth = this.state.currentScreenWidth >= 1440;

  render() {
    const { t } = this.props;
    const { isVideoLoaded, shouldStartLoading, handleVideoLoaded } = this.props;
    return (
      <React.Fragment>
        <section id="home" className="cursor-light p-0">
          <div className={style.backgroundVideoContainer}>
            <div className={style.bgOverlay}></div>
            {!isVideoLoaded && (
              <img
                src={
                  this.mobileWidth
                    ? "images/bgDesktop.webp"
                    : "images/bgMobile.webp"
                }
                alt=""
              />
            )}
            {shouldStartLoading && (
              <video
                src={
                  this.mobileWidth
                    ? "videos/bgDesktop.webm"
                    : "videos/bgMobile.webm"
                }
                onCanPlayThrough={handleVideoLoaded}
                autoPlay
                loop
                muted
                playsInline
              ></video>
            )}
          </div>
          <div
            className="fullscreen-container"
          >
            <div
              className="z-index-1"
            >
              <div
                className="landing-main"
              >
                <div className="col-md-6 landing-div2">
                  <div className="landing-div1">
                    <h1 className="text-white">
                      <span className="home-title">
                        {t("home.title")}{" "}
                      </span>
                      {t("home.datascience")}
                    </h1>
                  </div>
                  <p className="landing-des text-white">
               
                    {t("home.description1")}
                    <br /> <br />
                    {t("home.description2")}
                  </p>

                  <ConnectNowModel />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

const WrappedHome = (props) => {
  const { t } = useTranslation();
  return <Home {...props} t={t} />;
};

export default WrappedHome;
