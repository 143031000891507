import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import ApplyForJobModal from "../Modals/ApplyForJobModal";
import HireAIExpertModal from "../Modals/HireAIExpertModal/HireAIExpertModal";
import { capitalizeFirstLetter } from "../../utils/string";
import SuccessModal from "./SucceesModal";

const ConnectNowModal = () => {
  const { t } = useTranslation();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isUsingEmailForm, setIsUsingEmailForm] = useState(false);
  const [currentCategory, setCurrentCategory] = useState("collaborate");
  const [errorMessage, setErrorMessage] = useState(null);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [formStatus, setFormStatus] = useState({
    isLoading: false,
    isSuccess: false,
    error: null,
  });
  const [isSuccessModalVisible, setIsSuccessModalVisible] = useState(false);

  const handleSuccess = () => {
    setIsSuccessModalVisible(true);
  };
  const handleCloseSuccessModal = () => {
    setIsSuccessModalVisible(false);
  };

  const resetForm = () => {
    setIsModalVisible(false);
    setErrorMessage(null);
    setIsUsingEmailForm(false);
    setPhoneNumber("");
    setEmail("");
    setFormStatus({ isLoading: false, isSuccess: false, error: null });
  };

  const submitWhatsappMessage = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(`${process.env.REACT_APP_WHATSAPP_API_CALL}send`, {
        ...data,
        category: currentCategory,
      });
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };

  const submitEmailForm = async (data) => {
    try {
      setFormStatus({ ...formStatus, isLoading: true });
      await axios.post(
        `${process.env.REACT_APP_APIURL}contact`,
        { ...data, category: currentCategory },
        {
          headers: { "Content-Type": "application/json" },
        }
      );
      setFormStatus({ isLoading: false, isSuccess: true });
      resetForm();
      handleSuccess();
    } catch (err) {
      setFormStatus({ isLoading: false, error: "Something went wrong!" });
    }
  };
  useEffect(() => {
    if (formStatus.error) {
      setTimeout(() => setFormStatus({ ...formStatus, error: null }), 3000);
    }
  }, [formStatus]);


  return (
    <>
      <div
        className="landing-btn"
        style={{ display: "flex", alignItems: "center" }}
      >
        <button
          id="hireai-expert-btn"
          className="whatsapp_color_replace1 whatsapp_connect_now_btn"
          onClick={() => {
            setIsModalVisible(true);
            setCurrentCategory("collaborate");
          }}
        >
          {t("home.connectnow1")}
        </button>
      </div>
      <p className="text-look text-white apply-now-text">
        {t("hirenow.lookingforjob")}{" "}
        <span
          className="text-white link-job"
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsModalVisible(true);
            setCurrentCategory("career");
          }}
        >
          {t("home.apply")}
        </span>
      </p>
      {currentCategory === "career" ? (
        <ApplyForJobModal
          isModalVisible={isModalVisible}
          resetForm={resetForm}
          formStatus={formStatus}
          isUsingEmailForm={isUsingEmailForm}
          errorMessage={errorMessage}
          setIsUsingEmailForm={setIsUsingEmailForm}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      ) : (
        <HireAIExpertModal
          isModalVisible={isModalVisible}
          resetForm={resetForm}
          formStatus={formStatus}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          handleEmailSubmission={submitEmailForm}
          handleWhatsappSubmission={submitWhatsappMessage}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      )}
      {isSuccessModalVisible && (
        <SuccessModal
          SuccessModalVisible={isSuccessModalVisible}
          onclose={handleCloseSuccessModal}
        />
      )}
    </>
  );
};

export default ConnectNowModal;
