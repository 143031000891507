import {
    LOG_IN,
    LOG_IN_ERR,
    LOG_IN_REQ,
    LOG_OUT,
    REGISTER,
    REGISTER_ERR,
    REGISTER_REQ,
    RESEND_TOKEN,
    TOGGLE_AUTH_LOADER,
    VERIFY_ACC,
    VERIFY_ACC_ERR,
    HIRE_NOW_REGISTER,
    HIRE_NOW_REGISTER_ERR,
    HIRE_NOW_REGISTER_REQ,
    HIRE_NOW_DATA,
    INCREMENT,
    DECREMENT,
    RESET,
    REQ_DATA,
    TECH_DATA
} from "./actionTypes";
import axios from "axios";
import { getHeaders } from "../../utils/getHeaders";

export const logIn = (data, history) => {
    return dispatch => {
        dispatch({
            type: LOG_IN_REQ
        });
        const url = process.env.REACT_APP_APIURL + "signin";
        axios
            .post(url, data, getHeaders())
            .then(res => {
                if (res.data.success) {
                    dispatch({
                        type: LOG_IN,
                        user: res.data.user,
                        token: res.data.token,
                        tabs: res.data.tabs
                    });
                    history.push(res.data.tabs[0].path);
                } else {
                    dispatch({
                        type: LOG_IN_ERR,
                        payload: res.data.message
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: LOG_IN_ERR,
                    payload: JSON.parse(JSON.stringify(err)).message
                });
            });
    };
};

export const logOut = history => {
    return dispatch => {
        dispatch({
            type: LOG_OUT
        });
        history.push("/");
    };
};

export const verifyAccount = (token, history) => {
    return dispatch => {
        dispatch({
            type: TOGGLE_AUTH_LOADER,
            loaderType: "verify",
            value: true
        });
        axios
            .post(process.env.REACT_APP_APIURL + "verify-account", {
                token: token
            })
            .then(res => {
                let data = {
                    custom_status: res.data.custom_status,
                    user: res.data.user ? res.data.user : null,
                    tabs: res.data.tabs ? res.data.tabs : null,
                    token: res.data.token ? res.data.token : null
                };
                dispatch({
                    type: VERIFY_ACC,
                    ...data
                });
                if (
                    res.data.custom_status === 1000 ||
                    res.data.custom_status === 1001
                ) {
                    setTimeout(() => {
                        history.push("/");
                    }, 3000);
                }
            })
            .catch(err => {
                dispatch({
                    type: VERIFY_ACC_ERR,
                    payload: JSON.parse(JSON.stringify(err)).message
                });
            });
    };
};

export const resendVerificationToken = (token, history) => {
    return dispatch => {
        dispatch({
            type: TOGGLE_AUTH_LOADER,
            loaderType: "verify",
            value: true
        });
        axios
            .post(process.env.REACT_APP_APIURL + "re-verification", {
                token: token
            })
            .then(res => {
                if (res.data.success) {
                    dispatch({
                        type: RESEND_TOKEN,
                        custom_status: res.data.custom_status,
                        message: res.data.message
                    });
                    setTimeout(() => {
                        history.push("/");
                    }, 3000);
                }
            })
            .catch(err => {
                dispatch({
                    type: VERIFY_ACC_ERR,
                    payload: JSON.parse(JSON.stringify(err)).message
                });
            });
    };
};

export const register = data => {

    return dispatch => {
        dispatch({
            type: REGISTER_REQ
        });
        const url = process.env.REACT_APP_APIURL + "signup";
        axios
            .post(url, data, getHeaders("multipart/form-data"))
            .then(res => {
                if (res.data.success) {
                    dispatch({
                        type: REGISTER,
                        payload:
                            "Registration completed successfully. Please check your registered email verification link."
                    });
                } else {
                    dispatch({
                        type: REGISTER_ERR,
                        payload: res.data.message
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: REGISTER_ERR,
                    payload: JSON.parse(JSON.stringify(err)).message
                });
            });
    };
};

export const hire_now_register = data => {
    return dispatch => {
        dispatch({
            type: HIRE_NOW_REGISTER_REQ
        });
        const url = process.env.REACT_APP_APIURL + "on-demand-developer";
        axios
            .post(url, data, getHeaders())
            .then(res => {
                if (res.data.success) {
                    dispatch({
                        type: HIRE_NOW_REGISTER,
                        payload:
                            "Registration completed successfully. Please check your registered email verification link."
                    });
                    window.location.replace(res.data.redirectLink);
                } else {
                    dispatch({
                        type: HIRE_NOW_REGISTER_ERR,
                        payload: res.data.errors
                    });
                }
            })
            .catch(err => {
                dispatch({
                    type: HIRE_NOW_REGISTER_ERR,
                    payload: JSON.parse(JSON.stringify(err)).message
                });
            });
    };
};

export const setHireNowData = data => {
    return dispatch => {
        dispatch({
            type: HIRE_NOW_DATA,
            payload: data
        });
    };
};

export const setTechData = data => {
    return dispatch => {
        dispatch({
            type: TECH_DATA,
            payload: data
        });
    };
};

export const setReqData = data => {
    return dispatch => {
        dispatch({
            type: REQ_DATA,
            payload: data
        });
    };
};

export const increment = () => {
    return dispatch => {
        dispatch({ type: INCREMENT });
    };
};

export const decrement = () => {
    return dispatch => {
        dispatch({ type: DECREMENT });
    };
};

export const reset = () => {
    return dispatch => {
        dispatch({ type: RESET });
    };
};
