import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

const DeveloperRegistration = ({ isSideBar }) => {
    const {t} = useTranslation();
    return (
        <React.Fragment>
            {isSideBar ? (
                <li className="nav-item">
                    <Link
                        className="nav-link link"
                        to={"/careers"}
                        id="btn_sideNavLogin"
                    >
                        {t("navbar.button1")}
                    </Link>
                </li>
            ) : (
                <Link className={"nav-link link"} to={"/careers"}>
                    {t("navbar.button1")}
                </Link>
            )}
        </React.Fragment>
    );
};

export default DeveloperRegistration;
