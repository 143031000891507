import React from "react";
import {Controller, useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {logIn} from "../../../redux/actions/sessionActions";
import {useHistory} from "react-router";
 
const LoginForm = () => {

    const {handleSubmit, control, errors} = useForm();
    const {errors: loginErrors, loaders} = useSelector(state => state.sessionHandler)
    const dispatch = useDispatch();
    const history = useHistory();

    const onSubmit = (data) => {
        dispatch(logIn(data, history));
    }

    return (
        <form className="default-form" onSubmit={handleSubmit(onSubmit)}>
            <div className={'row'}>

                <div className="col-sm-12">
                    {loginErrors.login !== null && <div className={`alert alert-danger`} style={{padding: 10}}>{loginErrors.login}</div>}
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className={'form-group'}>
                        <label htmlFor="email" className="required">
                            Email
                        </label>
                        <Controller
                            as={<input className={'form-control'}/>}
                            id={'email'}
                            name={'email'}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: true,
                                pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                            }}
                        />
                        <small className={'form-text text-danger'}>
                            {errors.email &&
                            <React.Fragment>
                                {errors.email.type === 'required' && <span>Email is required!</span>}
                                {errors.email.type === 'pattern' && <span>Email is not valid</span>}
                            </React.Fragment>
                            }
                        </small>
                    </div>
                </div>

                <div className="col-md-12 col-sm-12">
                    <div className={'form-group'}>
                        <label htmlFor="password" className="required">
                            Password
                        </label>
                        <Controller
                            as={<input type={'password'} className={'form-control'}/>}
                            id={'password'}
                            name={'password'}
                            control={control}
                            defaultValue={''}
                            rules={{
                                required: true,
                            }}
                        />
                        <small className={'form-text text-danger'}>
                            {errors.password &&
                            <React.Fragment>
                                {errors.password.type === 'required' && <span>Password is required!</span>}
                            </React.Fragment>
                            }
                        </small>
                    </div>
                </div>

                <div className="col-sm-12 text-center">
                    <button
                        type="submit"
                        disabled={loaders.login ? true : ''}
                        className="btn btn-small btn-gradient btn-rounded mt-4"
                        id="signup_submit_btn"
                    >
                        {loaders.login && <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true"/>}
                        <span>{loaders.login ? 'Logging in..' : 'Log In'}</span>
                    </button>
                </div>

            </div>
        </form>
    )
}

export default LoginForm;
