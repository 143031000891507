import React from "react";
import {useSelector} from "react-redux";
import {Route, Redirect} from "react-router-dom";

const ProtectedRoute = ({path, isExact = false, component}) => {

    const {isLoggedIn} = useSelector(state => state.sessionHandler)

    return isLoggedIn ?
        <Route path={path} exact={isExact} component={component}/>
        :
        <Redirect to={'/'}/>
}

export default ProtectedRoute;
