import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getDeveloperApplicationStatus} from '../../redux/actions/developersActions';
import {PHASE_STATUS_CLASSES, TIMELINE_TAIL_STATUS_CLASSES, USER_PROGRESS_STATUS_CLASSES} from "../../utils/constants";
import * as moment from 'moment';
import LoaderGif from "../UI/LoaderGif";

const ApplicationStatus = () => {
    const dispatcher = useDispatch();
    const {user} = useSelector(state => state.sessionHandler)

    const {application_status_loader, application_status_data} = useSelector(state => state.applicationStatusHandler);

    useEffect(() => {
        if (user) {
            dispatcher(getDeveloperApplicationStatus(user.id));
        }
    }, []);

    const refreshApplicationStatus = () => {
        if (user) {
            dispatcher(getDeveloperApplicationStatus(user.id));
        }
    }

    const renderStatusLoader = () => {
        return (
            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px"}}>
                <LoaderGif/>
            </div>
        )
    }

    return (
        <section id="sec_2" style={{paddingBottom: "4rem"}}>
            <div className='container'>
                <div className='row justify-content-center'>
                    {!application_status_loader && application_status_data.progress_status.status.id === 3 &&
                    <div className={'col-lg-10 col-md-8 col-12 mb-3'}>
                        <div className={'section-message'}>
                            <div className={'message-icon message-icon--danger'}>
                                <i className="fa fa-exclamation" aria-hidden="true"/>
                            </div>
                            <div className={'message-body'}>
                                <h4 className={'message-body--header'}>Application Rejected</h4>
                                <p className={'message-body--text'}>{application_status_data.progress_status.rejection_reason}</p>
                            </div>
                        </div>
                    </div>
                    }
                    {!application_status_loader && application_status_data.progress_status.status.id === 2 &&
                    application_status_data.recruitment_phases.map(phase => (
                        phase.progress_status_id === 2 && phase.phase_interview_schedule &&
                        <div className={'col-lg-10 col-md-8 col-12 mb-3'} key={`interview-details-${phase.id}`}>
                            <div className={'section-message'}>
                                <div className={'message-icon'} style={{background: '#00BBFF'}}>
                                    <i className="fa fa-exclamation" aria-hidden="true"/>
                                </div>
                                <div className={'message-body'}>
                                    <div className={'message-body--header d-flex justify-content-between align-items-center'}>
                                        <h4 className={'title m-0'}>Meeting Details</h4>
                                        <button className={'btn btn-theme-secondary join-btn btn-rounded px-2 py-0'}
                                                style={{fontSize: 14, lineHeight: 1.5}}
                                                disabled={phase.phase_interview_schedule.join_url !== null ? '' : true}
                                                onClick={() => window.location = phase.phase_interview_schedule.join_url}
                                        >
                                            Join Meeting
                                        </button>
                                    </div>
                                    {phase.phase_interview_schedule.date_time !== null ?
                                        <div className={'row'}>
                                            <div className={'col-12'}>
                                                <span>Date : </span>
                                                <span>{moment(phase.phase_interview_schedule.date_time).format('MMMM DD, YYYY (dddd)')}</span>
                                            </div>
                                            <div className={'col-12'}>
                                                <span>Time : </span>
                                                <span>{moment(phase.phase_interview_schedule.date_time).format('hh:mm A')}</span>
                                            </div>
                                        </div>
                                        :
                                        <div>
                                            Details will be updated shortly
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className={'col-lg-10 col-md-8 col-12'}>
                        <div className="price-two bg-white item-shadow developer-card border">
                            <div className={'developer-card--header align-items-center'}>
                                <div className={'d-flex align-items-center'}>
                                    <h5 className="alt-font font-weight-normal">Application Status</h5>
                                    {!application_status_loader && application_status_data.progress_status.status.id !== 3 &&
                                    <div className={'ml-3'}>
                                        <span
                                            className={[
                                                'badge', 'badge-pill', 'text-capitalize', 'py-1', 'px-2',
                                                'badge-' + USER_PROGRESS_STATUS_CLASSES[application_status_data.progress_status.status.id]
                                            ].join(' ')}
                                            style={{fontSize: 12}}>
                                            {application_status_data.progress_status.status.name}
                                        </span>
                                    </div>
                                    }
                                </div>
                                <a className="refresh-icon" onClick={refreshApplicationStatus}>
                                    <i className="fa fa-refresh" aria-hidden="true"/>
                                </a>
                            </div>

                            <div className={'developer-card--content'}>
                                {application_status_loader ? renderStatusLoader() :
                                    <div className="timeline-center">
                                        <ul>
                                            {application_status_data.recruitment_phases && application_status_data.recruitment_phases.map(item => (
                                                <li key={item.id}>
                                                    <div className={['tail', TIMELINE_TAIL_STATUS_CLASSES[item.progress_status_id]].join(' ')}/>
                                                    <div className={["tail-head", PHASE_STATUS_CLASSES[item.progress_status_id]].join(' ')}>
                                                        {item.progress_status_id === 4 && <i className={'fa fa-check'}/>}
                                                        {item.progress_status_id === 3 && <i className={'fa fa-times'}/>}
                                                    </div>
                                                    <div className="content">
                                                        {item.phase.name}
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ApplicationStatus;
