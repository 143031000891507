export const inputProps = {
  border: "1px solid #ced1da",
  color: "#777777",
  fontWeight: "400",
  height: "48px",
  borderRadius: "0 8px 8px 0",
  backgroundColor: "#FAFAFA",
};
export const countrySelectorStyleProps = {
  buttonStyle: {
    border: "1px solid #ced1da",
    borderRadius: "8px 0 0 8px",
    height: "48px",
    backgroundColor: "#FAFAFA",
  },
};
