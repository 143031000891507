import React from "react";
import {OverlayTrigger, Popover} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {logOut} from "../../../redux/actions/sessionActions";
import {useHistory} from "react-router";

const UserProfile = ({isSideBar}) => {

    const {user} = useSelector(state => state.sessionHandler);
    const logoutDispatcher = useDispatch();
    const history = useHistory();


    const onLogOut = () => {
        logoutDispatcher(logOut(history));
    };

    const menu = (
        <Popover id="popover-basic" className={'profile-menu'} placement={'left'}>
            <Popover.Content className={'filter-menu'} style={{width: 200}}>
                <a
                    className="dropdown-item"
                    onClick={onLogOut}
                    data-toggle="popover"
                >
                    Logout
                    <i className="fa fa-sign-out" aria-hidden="true"/>
                </a>
            </Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger
            trigger="click"
            placement="bottom-start"
            overlay={menu}
            rootClose
        >
            {isSideBar ?
                <li className={'nav-item'}>
                    <a className={`user-name link ${user.user_type === 3 && 'developer-nav'}`} style={{cursor: 'pointer'}}>
                        <i className="fa fa-user-circle" aria-hidden="true" style={{marginLeft: 0, marginRight: 5}}/>
                        {user.first_name}
                    </a>
                </li>
                :
                <a className={`nav-link link ${user.user_type === 3 && 'developer-nav'}`} style={{cursor: 'pointer'}}>
                    <i className="fa fa-user-circle" aria-hidden="true" style={{marginRight: 5}}/>
                    {user.first_name}
                </a>
            }
        </OverlayTrigger>
    )
}

export default UserProfile;
