import React from "react";

const Calendly = () => {

    return (
        <section id="sec_4" style={{paddingBottom: "4rem"}}>
            <div>Calendly Page</div>
        </section>
    )
}
export default Calendly;