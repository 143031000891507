import axios from "axios";
import {
    GET_DEVELOPERS_REQ,
    GET_DEVELOPERS,
    UPDATE_DEV_STATUS_REQ,
    UPDATE_DEV_STATUS_SUC,
    GET_RECRUITMENT_PHASE_REQ,
    GET_RECRUITMENT_PHASE_SUC,
    GET_DEVELOPER_APPLICATION_STATUS_REQ,
    GET_DEVELOPER_APPLICATION_STATUS,
    GET_DEVELOPERS_JOB_TITLE_LIST_REQ,
    GET_DEVELOPERS_JOB_TITLE_LIST,
    GET_DEVELOPERS_JOB_TITLE_LIST_ERR,
} from "./actionTypes";
import { getAccessToken, getAuthHeaders } from "../../utils/getHeaders";

export const getDevelopers = (pageNumber, filterData) => {
    const data = {
        pageNumber: pageNumber,
        filterData: filterData,
    };
    return (dispatch) => {
        dispatch({
            type: GET_DEVELOPERS_REQ,
            payload: true,
        });
        
        const url = process.env.REACT_APP_APIURL + "developers";
        axios
            .post(url, data, getAuthHeaders())
            .then((res) => {
                dispatch({
                    type: GET_DEVELOPERS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const updateDeveloperStatus = (data, id, actionType) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_DEV_STATUS_REQ,
            payload: { type: actionType, value: id },
        });
        const url = process.env.REACT_APP_APIURL + "developers/" + id;
        axios
            .patch(url, data, getAuthHeaders())
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: UPDATE_DEV_STATUS_SUC,
                        loaderType: actionType,
                        payload: res.data.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getRecruitmentPhases = () => {
    return (dispatch) => {
        dispatch({
            type: GET_RECRUITMENT_PHASE_REQ,
        });
        const url = process.env.REACT_APP_APIURL + "recruitment-phases";
        axios
            .get(url, getAuthHeaders())
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: GET_RECRUITMENT_PHASE_SUC,
                        payload: res.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getDeveloperApplicationStatus = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_DEVELOPER_APPLICATION_STATUS_REQ,
            payload: true,
        });
        const url = process.env.REACT_APP_APIURL + "developers/" + id;
        axios
            .get(url, getAuthHeaders())
            .then((res) => {
                dispatch({
                    type: GET_DEVELOPER_APPLICATION_STATUS,
                    payload: res.data.data,
                });
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const resumeDownload = (id, fileName) => {
    return (dispatch) => {
        const FileDownload = require("js-file-download");
        const url =
            process.env.REACT_APP_APIURL +
            "developers/" +
            id +
            "/resume-download";
        axios
            .get(url, {
                responseType: "blob",
                headers: {
                    Authorization: `Bearer ${getAccessToken()}`,
                    "Content-type": "application/json",
                },
            })
            .then((res) => {
                let ext = ".pdf";

                if (
                    res.headers["content-type"] ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                ) {
                    ext = ".docx";
                } else if (
                    res.headers["content-type"] === "application/msword"
                ) {
                    ext = ".doc";
                } else if (
                    res.headers["content-type"] ===
                    "application/vnd.openxmlformats-officedocument.wordprocessingml.template"
                ) {
                    ext = ".dotx";
                } else if (res.headers["content-type"] === "	application/vnd.ms-word.template.macroEnabled.12") {
                    ext=".dotm";
                }
                fileName = fileName + ext;
                FileDownload(res.data, fileName);
            })
            .catch((err) => {
                console.log(err);
            });
    };
};

export const getDeveloperJobTitleList = () => {
    return (dispatch) => {
        dispatch({
            type: GET_DEVELOPERS_JOB_TITLE_LIST_REQ,
        });
        const url = process.env.REACT_APP_APIURL + "job-titles";
        axios
            .get(url, getAuthHeaders())
            .then((res) => {
                if (res.data.success) {
                    dispatch({
                        type: GET_DEVELOPERS_JOB_TITLE_LIST,
                        payload: res.data.data,
                    });
                }
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_DEVELOPERS_JOB_TITLE_LIST_ERR,
                    payload: err,
                });
            });
    };
};
