const scriptLoader = (obj, callback = null, callbackObject = null ) => {
    let script = document.createElement("script");
    script.src = obj.src;
    script.id = obj.id;
    script.type = "text/javascript";
    script.async = obj.async;
    script.defer = obj.defer;
    if(callback != null)  {
        script.onload = () => {
            callback(callbackObject);
        }
    }
    document.body.appendChild(script);
}

export default scriptLoader;
