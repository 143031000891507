import React from "react";
import {Helmet} from "react-helmet";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export default function Hirenow() {
    const history = useHistory();
    const { t } = useTranslation();
    return (
        <React.Fragment>
            <Helmet>
                <title>Join Our Team</title>
                <meta name="description" content="Become part of a pioneering team that's changing the world with AI. Discover career opportunities that shape the future." />
            </Helmet>
            <div style={{paddingTop:"140px"}} className="container-carrer">
                <div className="col-md-6 mt-0">
                    <h4 className="text-l1">{t("careers.fordevelopers")}</h4>
                    <h1 className="text-l2">
                    {t("careers.desc1")}
                    </h1>
                    <p className="text-l3">
                    {t("careers.desc2")}
                    </p>

                    <button
                        className="btn btn-apply"
                        onClick={() => history.push("/apply_now")}
                    >
                       {t("careers.applynow")}
                    </button>

                    <p className="text-look">
                    {t("hirenow.lookingforjob")}{" "}
                        <Link className="link-job" to="/hirenow">
                        {t("careers.hiredevelopers")}
                        </Link>
                    </p>
                </div>
                <div className="col-md-6 mt-0 main-img2">
                    <img
                        src="/images/frame-carrer.svg"
                        className="carrer-img"
                        alt="img"
                    />
                </div>
            </div>
        </React.Fragment>
    );
}
