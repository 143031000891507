import {MAX_PAGINATION_ITEMS} from "./constants";

export const getPaginationPages = (currentPage, lastPage) => {
    let pages = [];
    const lastPageOffset = lastPage - currentPage;
    const firstPageOffset = currentPage - 1;
    const numberOfSideItems = (MAX_PAGINATION_ITEMS - 1) / 2;

    if (lastPage > MAX_PAGINATION_ITEMS) {
        if (firstPageOffset < (MAX_PAGINATION_ITEMS + 1) / 2) {
            pages = [...Array(MAX_PAGINATION_ITEMS).keys()].map(i => i + 1);
            return pages;
        } else {
            if (lastPageOffset <= numberOfSideItems) {
                pages = [...Array(MAX_PAGINATION_ITEMS).keys()].map(i => i + (currentPage - numberOfSideItems - (numberOfSideItems - lastPageOffset)));
                return pages;
            } else {
                pages = [...Array(MAX_PAGINATION_ITEMS).keys()].map(i => i + (currentPage - numberOfSideItems));
                return pages;
            }
        }
    } else {
        pages = [...Array(lastPage).keys()].map(i => i + 1);
        return pages;
    }
}
