import i18n from "i18next";
import {  initReactI18next } from "react-i18next";
import enJSON from './locales/en.json'
import esJSON from './locales/es.json'
import deJSON from './locales/de.json'
import frJSON from './locales/fr.json'
import itJSON from './locales/it.json'
import ptJSON from './locales/pt.json'
import jpJSON from './locales/jp.json'
import ilJSON from './locales/il.json'
import auJSON from './locales/au.json'
import thJSON from './locales/th.json'
import aeJSON from './locales/ae.json'

i18n.use(initReactI18next).init({
  resources: {
    en: { ...enJSON },
    es: { ...esJSON },
    de: { ...deJSON },
    fr: { ...frJSON },
    it: { ...itJSON },
    pt: { ...ptJSON },
    jp: { ...jpJSON },
    il: { ...ilJSON },
    au: { ...auJSON },
    th: { ...thJSON },
    ae: { ...aeJSON }
  },
  lng: "en",
});
export default i18n;