import React from "react";
import {PHASE_STATUS_CLASSES, TIMELINE_TAIL_STATUS_CLASSES, USER_PROGRESS_STATUS_CLASSES} from "../../utils/constants";
import {useDispatch, useSelector} from "react-redux";
import {resumeDownload, updateDeveloperStatus} from '../../redux/actions/developersActions';
import {Controller, useForm} from "react-hook-form";
import {Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import {TOGGLE_DEVELOPER_MODALS} from "../../redux/actions/actionTypes";
import moment from "moment";

const ACTION_TYPE = {
    3: 'reject',
    4: 'accept'
}

export const ScheduleDetails = ({scheduleDetails}) => {

    const {join_url: joinUrl, date_time: dateTime} = scheduleDetails;

    return (
        <div className={'col-12 schedule-details'}>
            <div className={'schedule-details--header'}>
                <p>Meeting Details</p>
            </div>
            <div className={'schedule-details--content'}>
                {dateTime !== null ?
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <span className={'text-secondary'}>Date : </span>
                            <span>{moment(dateTime).format('MMMM DD, YYYY (dddd)')}</span>
                        </div>
                        <div className={'col-12'}>
                            <span className={'text-secondary'}>Time : </span>
                            <span>{moment(dateTime).format('hh:mm A')}</span>
                        </div>
                    </div>
                    :
                    <div>
                        Details will be updated shortly
                    </div>
                }
                <button className={'btn join-btn btn-theme-primary btn-xs btn-rounded'}
                        disabled={joinUrl !== null ? '' : true}
                        onClick={() => window.location = joinUrl}
                >
                    Join Meeting
                </button>
            </div>
        </div>
    )
}

const DeveloperCard = ({developer}) => {

    const {handleSubmit, control, watch, errors} = useForm();
    const {id, name, email, position, year, month, progress_status, recruitment_phases, created_at} = developer;
    const {updateLoader, showModal, getJobTitleListLoader, jobTitles, secondLastRecruitmentPhase} = useSelector(state => state.developersHandler);
    const dispatch = useDispatch();

    const devOnBoardingPosition = parseInt(watch('job_title_id')) || jobTitles[0] && jobTitles[0].id;

    const actionClicked = (phaseId, statusId) => {
        const data = {
            recruitment_phase_id: phaseId,
            progress_status_id: statusId
        }
        dispatch(updateDeveloperStatus(data, id, ACTION_TYPE[statusId]));
    }

    const downloadResume = () => {
        const fileName = name + ' Developer Resume'
        dispatch(resumeDownload(id, fileName))
    }

    const toggleRejectionModal = (phaseId, is_show) => {
        dispatch({
            type: TOGGLE_DEVELOPER_MODALS,
            payload: {
                type: 'reject',
                id: is_show ? id + '_' + phaseId : 0
            },
        })
    }

    const toggleOnBoardingModal = (phaseId, is_show) => {
        dispatch({
            type: TOGGLE_DEVELOPER_MODALS,
            payload: {
                type: 'onBoarding',
                id: is_show ? id + '_' + phaseId : 0
            },
        })
    }

    const onReject = (data) => {
        const formData = {
            ...data,
            progress_status_id: 3,
        }
        dispatch(updateDeveloperStatus(formData, id, ACTION_TYPE[3]));
    }

    const onBoardingSuccess = (data) => {
        const formData = {
            custom_job_title: data.custom_job_title ? data.custom_job_title : null,
            job_title_id: parseInt(data.job_title_id),
            progress_status_id: 4,
            recruitment_phase_id: data.recruitment_phase_id
        }
        dispatch(updateDeveloperStatus(formData, id, 'onBoarding'));
    }

    const onPhaseAccept = (phase) => {
        if (phase.recruitment_phase_id === secondLastRecruitmentPhase.recruitment_phase_id) {
            toggleOnBoardingModal(phase.recruitment_phase_id, true);
        } else {
            actionClicked(phase.recruitment_phase_id, 4);
        }
    }

    return (
        <div className={'col-lg-4 col-md-6 col-sm-12'} style={{marginTop: 15, marginBottom: 15}} key={id}>
            <div className="price-two bg-white item-shadow developer-card">
                <div className={'developer-card--header'}>
                    <div className={'text-truncate'}>
                        <h5 className="alt-font font-weight-normal mb-1 text-truncate">{name}</h5>
                        <OverlayTrigger
                            key={'email'}
                            placement={'top'}
                            overlay={<Tooltip id={`email`}>{email}</Tooltip>}
                        >
                            <p className="alt-font text-secondary font-weight-normal mb-0 text-truncate">{email}</p>
                        </OverlayTrigger>
                        {position && <p className="alt-font text-secondary font-weight-normal mb-0 text-truncate">{`${position} ${year} yrs ${month} mo.`}</p> }
                        <OverlayTrigger
                            key={'register-date'}
                            placement={'top'}
                            overlay={
                                <Tooltip id={`register-date`}>
                                    {new Date(created_at).toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric'})}
                                </Tooltip>
                            }
                        >
                            <p className="alt-font text-secondary font-weight-normal mb-0 text-truncate">
                                Registered On: {new Date(created_at).toLocaleDateString('en-US', {day: 'numeric', month: 'short', year: 'numeric'})}
                            </p>
                        </OverlayTrigger>
                    </div>
                    <div>
                        <span
                            className={[
                                'badge', 'badge-pill', 'text-capitalize', 'py-1', 'px-2',
                                'badge-' + USER_PROGRESS_STATUS_CLASSES[progress_status.status.id]
                            ].join(' ')}
                            style={{fontSize: 12}}>
                            {progress_status.status.name}
                        </span>
                    </div>
                </div>
                <div className={'developer-card--content'}>
                    <ul className="timeline" style={{letterSpacing: 'normal'}}>
                        {recruitment_phases.length && recruitment_phases.map((phase, index) => (
                            <li className={'timeline-item'} key={index}>
                                {index !== (recruitment_phases.length - 1) &&
                                <div className={['timeline-item-tail', TIMELINE_TAIL_STATUS_CLASSES[phase.progress_status_id]].join(' ')}/>
                                }
                                <div className={["timeline-item-head", PHASE_STATUS_CLASSES[phase.progress_status_id]].join(' ')}>
                                    {phase.progress_status_id === 4 && <i className={'fa fa-check'}/>}
                                    {phase.progress_status_id === 3 && <i className={'fa fa-times'}/>}
                                </div>
                                <div className={'timeline-item-content row no-gutters'}>
                                    <div className={"col-12"}>{phase.phase.name}</div>
                                    {phase.phase_interview_schedule && phase.progress_status_id === 2 &&
                                    <ScheduleDetails scheduleDetails={phase.phase_interview_schedule}/>
                                    }
                                    {phase.progress_status_id === 2 &&
                                    <React.Fragment>
                                        <div className={'col-12 phase-actions'}>
                                            <button key={'accept'}
                                                    className={'btn btn-xs btn-gradient'}
                                                    disabled={updateLoader.accept === id ? true : ''}
                                                    onClick={() => onPhaseAccept(phase)}
                                            >
                                                <i className={['fa'].concat(updateLoader.accept === id ? ['fa-spinner', 'fa-spin'] : ['fa-check']).join(' ')}
                                                   aria-hidden={'true'}/>
                                            </button>
                                            <button key={'reject'}
                                                    className={'btn btn-xs btn-theme-danger'}
                                                    disabled={updateLoader.reject === id ? true : ''}
                                                    onClick={() => toggleRejectionModal(phase.recruitment_phase_id, true)}
                                            >
                                                <i className={['fa'].concat(updateLoader.reject === id ? ['fa-spinner', 'fa-spin'] : ['fa-times']).join(' ')}
                                                   aria-hidden={'true'}/>
                                            </button>
                                        </div>
                                        <Modal show={showModal.reject === id + '_' + phase.recruitment_phase_id}
                                               onHide={() => toggleRejectionModal(0, false)}
                                               aria-labelledby={`reject-modal-title-vcenter_${id}`}
                                               centered
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title id={`reject-modal-title-vcenter_${id}`}>
                                                    Rejection Reason
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <form className={'consult-form'}
                                                      onSubmit={handleSubmit(onReject)}
                                                >

                                                    <Controller
                                                        as={<input hidden/>}
                                                        id={'recruitment_phase_id'}
                                                        name={'recruitment_phase_id'}
                                                        control={control}
                                                        defaultValue={phase.recruitment_phase_id}
                                                    />

                                                    <div className={'form-group'}>
                                                        <label htmlFor="rejection_reason" className="required">
                                                            Please provide rejection reason
                                                        </label>
                                                        <Controller
                                                            as={<textarea className={'form-control'}/>}
                                                            id={'rejection_reason'}
                                                            name={'rejection_reason'}
                                                            control={control}
                                                            defaultValue={''}
                                                            rules={{required: true}}
                                                        />
                                                        <small className={'form-text text-danger'}>
                                                            {errors.rejection_reason && <span>Rejection reason is required!</span>}
                                                        </small>
                                                    </div>
                                                    <div className={'text-center'}>
                                                        <button
                                                            type="submit"
                                                            className="btn btn-small btn-gradient btn-rounded"
                                                            id={"reject_btn_" + id}
                                                            disabled={updateLoader.reject === id ? true : ''}
                                                        >
                                                            {updateLoader.reject === id &&
                                                            <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true"/>
                                                            }
                                                            <span>Submit</span>
                                                        </button>
                                                    </div>
                                                </form>
                                            </Modal.Body>
                                        </Modal>

                                        <Modal show={showModal.onBoarding === id + '_' + phase.recruitment_phase_id}
                                               onHide={() => toggleOnBoardingModal(0, false)}
                                               aria-labelledby={`onboarding-modal-title-vcenter_${id}`}
                                               centered
                                        >
                                            <Modal.Header closeButton>
                                                <Modal.Title id={`onboarding-modal-title-vcenter_${id}`}>
                                                    Developer Job Title
                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                {getJobTitleListLoader ?
                                                    <div>Loading</div>
                                                    :
                                                    <form className={'default-form'}
                                                          onSubmit={handleSubmit(onBoardingSuccess)}
                                                    >

                                                        <Controller
                                                            as={<input hidden/>}
                                                            id={'recruitment_phase_id'}
                                                            name={'recruitment_phase_id'}
                                                            control={control}
                                                            defaultValue={phase.recruitment_phase_id}
                                                        />

                                                        <div className={'form-group'}>
                                                            <label htmlFor="job_title_id" className="required">
                                                                Please select developer job title
                                                            </label>
                                                            <Controller
                                                                as={
                                                                    <select className={'form-control'}>
                                                                        {jobTitles.map(title => (
                                                                            <option key={title.id} value={title.id}>{title.title}</option>
                                                                        ))}
                                                                    </select>
                                                                }
                                                                id={'job_title_id'}
                                                                name={'job_title_id'}
                                                                control={control}
                                                                defaultValue={jobTitles[0].id}
                                                                rules={{required: true}}
                                                            />
                                                            <small className={'form-text text-danger'}>
                                                                {errors.job_title_id && <span>Please select any position</span>}
                                                            </small>
                                                        </div>

                                                        {devOnBoardingPosition === 1 &&
                                                        <div className={'form-group'}>
                                                            <label htmlFor="custom_job_title" className="required">
                                                                Enter custom job title
                                                            </label>
                                                            <Controller
                                                                as={<input className={'form-control'}/>}
                                                                id={'custom_job_title'}
                                                                name={'custom_job_title'}
                                                                control={control}
                                                                defaultValue={''}
                                                                rules={{required: true}}
                                                            />
                                                            <small className={'form-text text-danger'}>
                                                                {errors.custom_job_title && <span>Position is required!</span>}
                                                            </small>
                                                        </div>
                                                        }

                                                        <div className={'text-center'}>
                                                            <button
                                                                type="submit"
                                                                className="btn btn-small btn-gradient btn-rounded"
                                                                id={"reject_btn_" + id}
                                                                disabled={updateLoader.onBoarding === id ? true : ''}
                                                            >
                                                                {updateLoader.onBoarding === id &&
                                                                <i className="fa fa-spinner fa-spin mr-2" aria-hidden="true"/>
                                                                }
                                                                <span>Submit</span>
                                                            </button>
                                                        </div>
                                                    </form>
                                                }
                                            </Modal.Body>
                                        </Modal>
                                    </React.Fragment>
                                    }
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
                <div className={'developer-card--footer'}>
                    <button className="btn btn-sm btn-rounded btn-gradient" onClick={downloadResume}>
                        Resume
                        <i className={'fa fa-download ml-2'}/>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeveloperCard;
