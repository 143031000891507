import React from "react";
import {OverlayTrigger, Tooltip} from "react-bootstrap";

const Pagination = ({currentPage, lastPage, pages, goToPage}) => {

    return (
        <ul className="blog-pagination p-0 list-unstyled text-center">
            <OverlayTrigger key={'first-page'} placement={'top'}
                            overlay={<Tooltip id={'first-page'}>Go To First Page</Tooltip>}
            >
                <li>
                    <a onClick={() =>
                        currentPage !== 1 &&
                        goToPage(1)
                    }
                       className={[currentPage === 1 && 'disabled'].join(' ')}
                    >
                        <i className="fa fa-angle-double-left" aria-hidden="true"/>
                    </a>
                </li>
            </OverlayTrigger>
            <OverlayTrigger key={'prev-page'} placement={'top'}
                            overlay={<Tooltip id={'prev-page'}>Go To Previous Page</Tooltip>}
            >
                <li>
                    <a onClick={() =>
                        currentPage !== 1 &&
                        goToPage(currentPage - 1)
                    }
                       className={[currentPage === 1 && 'disabled'].join(' ')}
                    ><i className="fa fa-angle-left" aria-hidden="true"/></a>
                </li>
            </OverlayTrigger>
            {
                pages.map((page) => (
                    <li key={`pagination-item-${page}`}
                        className={[currentPage === (page) && 'active'].join(' ')}
                    >
                        <a onClick={() => currentPage !== page && goToPage(page)}>
                            {page}
                        </a>
                    </li>
                ))
            }
            <OverlayTrigger key={'next-page'} placement={'top'}
                            overlay={<Tooltip id={'next-page'}>Go To Next Page</Tooltip>}
            >
                <li>
                    <a onClick={() =>
                        currentPage !== lastPage &&
                        goToPage(currentPage + 1)
                    }
                       className={[currentPage === lastPage && 'disabled'].join(' ')}
                    >
                        <i className="fa fa-angle-right" aria-hidden="true"/>
                    </a>
                </li>
            </OverlayTrigger>
            <OverlayTrigger key={'last-page'} placement={'top'}
                            overlay={<Tooltip id={'last-page'}>Go To Last Page</Tooltip>}
            >
                <li>
                    <a onClick={() =>
                        currentPage !== lastPage &&
                        goToPage(lastPage)
                    }
                       className={[currentPage === lastPage && 'disabled'].join(' ')}
                    >
                        <i className="fa fa-angle-double-right" aria-hidden="true"/>
                    </a>
                </li>
            </OverlayTrigger>
        </ul>
    )
}

export default Pagination;
