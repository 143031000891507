export const getHeaders = (content='application/json') => {
    return {
        headers: {
            'Content-type': content,
            'Accept': content
        }
    }
}

export const getAuthHeaders = () => {
    const token = getAccessToken();
    return {
        headers: {
            'Authorization': `Bearer ${token}`,
            'Content-type': 'application/json',
            'Accept': 'application/json'
        }
    }
}

export const getAccessToken = () => {
    return localStorage.getItem('token');
}
