import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {getDeveloperJobTitleList, getDevelopers, getRecruitmentPhases} from '../../redux/actions/developersActions';
import DeveloperCard from "./DeveloperCard";
import {CHANGE_PHASE_FILTER_DATA, CHANGE_SEARCH_TERM_FILTER_DATA, CHANGE_STATUS_FILTER_DATA} from "../../redux/actions/actionTypes";
import {Dropdown, OverlayTrigger, Tooltip} from "react-bootstrap";
import Pagination from "../UI/Pagination";
import {DEVELOPER_PROCESS_STATUS} from "../../utils/constants";
import LoaderGif from "../UI/LoaderGif";

const Developers = () => {

    const dispatcher = useDispatch();
    const [dropdownOpen, setDropdownOpen] = useState({
        developerStatus: false,
        recruitmentPhasesStatus: false
    });
    const {mainLoader, filteredDevelopers, getRecruitmentPhaseLoader, filterData, recruitmentPhases, paginationData} = useSelector(state => state.developersHandler);

    useEffect(() => {
        removeMainLoader();
        dispatcher(getDevelopers(1, filterData));
        dispatcher(getRecruitmentPhases());
        dispatcher(getDeveloperJobTitleList());
    }, []);

    const removeMainLoader = () => {
        // document.getElementById("mainLoader").style.display = "none";
    };

    const toggleDropdowns = (key, value) => {
        setDropdownOpen({
            ...dropdownOpen,
            [key]: value
        })
    }

    const onSearch = (term) => {
        dispatcher({
            type: CHANGE_SEARCH_TERM_FILTER_DATA,
            payload: term
        })
        if (term.length === 0 || term.length > 2) {
            dispatcher(getDevelopers(1, {...filterData, searchTerm: term}));
        }
    }

    const onPhaseFilter = (phaseId) => {
        setDropdownOpen({
            ...dropdownOpen,
            recruitmentPhasesStatus: false
        });
        const phaseFilters = filterData.filters.phaseFilter;
        const index = phaseFilters.indexOf(phaseId);

        if (index === -1) {
            phaseFilters.push(phaseId)
        } else {
            phaseFilters.splice(index, 1)
        }
        dispatcher({
            type: CHANGE_PHASE_FILTER_DATA,
            payload: phaseFilters
        });
        dispatcher(getDevelopers(1, {...filterData, filters: {...filterData.filters, phaseFilter: phaseFilters}}))
    }

    const onDeveloperStatusFilter = (value) => {
        setDropdownOpen({
            ...dropdownOpen,
            developerStatus: false
        });
        const statusFilters = filterData.filters.statusFilter;
        const index = statusFilters.indexOf(value);

        if (index === -1) {
            statusFilters.push(value)
        } else {
            statusFilters.splice(index, 1)
        }
        dispatcher({
            type: CHANGE_STATUS_FILTER_DATA,
            payload: statusFilters
        });
        dispatcher(getDevelopers(1, {...filterData, filters: {...filterData.filters, statusFilter: statusFilters}}))
    }

    const clearFilters = (type) => {
        const data = filterData;
        if (type === 'statusFilter') {
            data.filters.statusFilter = [];
            dispatcher({
                type: CHANGE_STATUS_FILTER_DATA,
                payload: []
            })
        }
        if (type === 'phaseFilter') {
            data.filters.phaseFilter = [];
            dispatcher({
                type: CHANGE_PHASE_FILTER_DATA,
                payload: []
            })
        }
        if (type === 'searchTerm') {
            data.searchTerm = '';
            dispatcher({
                type: CHANGE_SEARCH_TERM_FILTER_DATA,
                payload: ''
            })
        }
        dispatcher(getDevelopers(1, data));
    }

    const onRefresh = () => {
        dispatcher(getDevelopers(paginationData.current_page, filterData));
    }

    const goToPage = (pageNumber) => {
        if (!mainLoader) {
            dispatcher(getDevelopers(pageNumber, filterData));
        }
    }

    return (
        <React.Fragment>
            <section id={'sec_1'} className={'bg-light'} style={{minHeight: '100vh'}}>
                <div className={'container'}>
                    <div className={'row'}>
                        <div className={'col-sm-12 col-md-12 col-lg-4'}>
                            <div className="widget d-flex bg-light px-0 py-3 py-xl-4">
                                <input className="search item-shadow"
                                       placeholder="Search.."
                                       type="text"
                                       style={{borderRadius: 10}}
                                       value={filterData.searchTerm}
                                       onChange={(e) => onSearch(e.target.value)}
                                />
                                <OverlayTrigger
                                    key={'search-btn'}
                                    placement={'top'}
                                    overlay={
                                        <Tooltip id={`search-btn`}>
                                            {filterData.searchTerm.length >= 3 ? 'Clear Search' : 'Search Using Term'}
                                        </Tooltip>
                                    }
                                >
                                    <a className="search-btn text-white"
                                       style={{right: 0, borderRadius: 10}}
                                       onClick={() => clearFilters('searchTerm')}
                                    >
                                        <i className={['fa', filterData.searchTerm.length >= 3 ? 'fa-times' : 'fa-search'].join(' ')} aria-hidden="true"/>
                                    </a>
                                </OverlayTrigger>
                                {filterData.searchTerm.length > 0 && filterData.searchTerm.length < 3 &&
                                <small style={{position: 'absolute', bottom: -5, left: 15}} className={'text-theme-danger'}>
                                    {filterData.searchTerm.length < 3 && 'Please enter at least 3 characters'}
                                </small>
                                }
                            </div>
                        </div>
                        <div className={'col-sm-12 col-md-5 col-lg-3'}>
                            <Dropdown className={'filter-dropdown px-0 py-3 py-xl-4'}
                                      show={dropdownOpen.developerStatus}
                                      onToggle={(val) => toggleDropdowns('developerStatus', val)}
                            >
                                <Dropdown.Toggle id="dropdown-basic" className={'btn btn-light filter-button text-left item-shadow'}>
                                    <span className={'text-truncate'} style={{textTransform: 'none'}}>
                                        {filterData.filters.statusFilter.length !== 0 &&
                                        <span className={'badge bg-theme-primary text-white mr-2'}>
                                            {filterData.filters.statusFilter.length}
                                        </span>}
                                        Status Filter
                                    </span>
                                    <OverlayTrigger
                                        key={'filter-btn'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`filter-btn`}>
                                                {filterData.filters.statusFilter.length !== 0 ? 'Clear filter' : 'Apply filter'}
                                            </Tooltip>
                                        }
                                    >
                                        <span className={'filter-icon'} onClick={() => clearFilters('statusFilter')}>
                                            <i className={['fa', filterData.filters.statusFilter.length !== 0 ? 'fa-times' : 'fa-filter'].join(' ')}/>
                                        </span>
                                    </OverlayTrigger>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={'filter-menu'} onClick={() => toggleDropdowns('developerStatus', true)}>
                                    {getRecruitmentPhaseLoader &&
                                    <div className={"dropdown-item"}>Loading</div>
                                    }
                                    {!getRecruitmentPhaseLoader && DEVELOPER_PROCESS_STATUS.map((status, index) => (
                                        <Dropdown.Item key={`status_${index}`}
                                                       className={[
                                                           'text-truncate',
                                                           filterData.filters.statusFilter.indexOf(status.value) !== -1 ? 'selected' : ''
                                                       ].join(' ')}
                                                       onClick={() => onDeveloperStatusFilter(status.value)}
                                        >
                                            {status.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className={'col-10 col-sm-10 col-md-6 col-lg-4'}>
                            <Dropdown className={'filter-dropdown px-0 py-3 py-xl-4'}
                                      show={dropdownOpen.recruitmentPhasesStatus}
                                      onToggle={(val) => toggleDropdowns('recruitmentPhasesStatus', val)}
                            >
                                <Dropdown.Toggle id="dropdown-basic" className={'btn btn-light filter-button text-left item-shadow'}>
                                    <span className={'text-truncate'} style={{textTransform: 'none'}}>
                                        {filterData.filters.phaseFilter.length !== 0 &&
                                        <span className={'badge bg-theme-primary text-white mr-2'}>
                                            {filterData.filters.phaseFilter.length}
                                        </span>}
                                        Phase Filter
                                    </span>
                                    <OverlayTrigger
                                        key={'filter-btn'}
                                        placement={'top'}
                                        overlay={
                                            <Tooltip id={`filter-btn`}>
                                                {filterData.filters.phaseFilter.length !== 0 ? 'Clear filter' : 'Apply filter'}
                                            </Tooltip>
                                        }
                                    >
                                        <span className={'filter-icon'} onClick={() => clearFilters('phaseFilter')}>
                                            <i className={['fa', filterData.filters.phaseFilter.length !== 0 ? 'fa-times' : 'fa-filter'].join(' ')}/>
                                        </span>
                                    </OverlayTrigger>
                                </Dropdown.Toggle>

                                <Dropdown.Menu className={'filter-menu'} onClick={() => toggleDropdowns('recruitmentPhasesStatus', true)}>
                                    {getRecruitmentPhaseLoader &&
                                    <div className={"dropdown-item"}>Loading</div>
                                    }
                                    {!getRecruitmentPhaseLoader && recruitmentPhases && recruitmentPhases.map(phase => (
                                        <Dropdown.Item key={`phase${phase.id}`}
                                                       className={[
                                                           'text-truncate',
                                                           filterData.filters.phaseFilter.indexOf(phase.id) !== -1 ? 'selected' : ''
                                                       ].join(' ')}
                                                       onClick={() => onPhaseFilter(phase.id)}
                                        >
                                            {phase.name}
                                        </Dropdown.Item>
                                    ))}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className={'col-2 col-sm-2 col-md-1 col-lg-1 p-0 px-md-2'}>
                            <div className={'px-0 py-3 py-xl-4'}>
                                <OverlayTrigger
                                    key={'refresh-button'}
                                    placement={'top'}
                                    overlay={<Tooltip id={`refresh-button`}>Refresh Developers</Tooltip>}
                                >
                                    <button className="refresh-icon" onClick={onRefresh}>
                                        <i className={['fa', 'fa-refresh', mainLoader && 'fa-spin'].join(' ')} aria-hidden="true"/>
                                    </button>
                                </OverlayTrigger>
                            </div>
                        </div>
                    </div>
                    <div className={'row'}>
                        {mainLoader ?
                            <div style={{display: "flex", justifyContent: "center", alignItems: "center", height: "300px", width: '100%'}}>
                                <LoaderGif/>
                            </div>
                            :
                            filteredDevelopers.length !== 0 ?
                                filteredDevelopers.map(item => (
                                    <DeveloperCard developer={item} key={item.id}/>
                                ))
                                :
                                <div style={{height: 150}} className={'d-flex justify-content-center align-items-center w-100'}>
                                    <h3>No Developers Found</h3>
                                </div>
                        }
                        {filteredDevelopers.length !== 0 &&
                        <div className={'col-12 mt-4'}>
                            <Pagination currentPage={paginationData.current_page}
                                        lastPage={paginationData.last_page}
                                        pages={paginationData.pages}
                                        goToPage={goToPage}
                            />
                        </div>
                        }
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
};

export default Developers;
