import {GET_DEVELOPER_APPLICATION_STATUS, GET_DEVELOPER_APPLICATION_STATUS_REQ} from "../actions/actionTypes";

const initState = {
    application_status_loader: true,
    application_status_data: []
}

const applicationStatusHandler = (state = initState, action) => {
    switch (action.type) {
        case GET_DEVELOPER_APPLICATION_STATUS_REQ:
            return {
                ...state,
                application_status_loader: action.payload
            }
        case GET_DEVELOPER_APPLICATION_STATUS:
            return {
                ...state,
                application_status_loader: false,
                application_status_data: action.payload
            }
        default:
            return state;
    }

}

export default applicationStatusHandler;
