import React, { Suspense } from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";

import Layout from "./Layout/Layout";
import {EmailVerification} from "./Components/Auth/EmailVerification";

const App = () => {

    return (
        <Router>
            <Switch>
                <Route
                    path="/verify"
                    exact
                    render={(props) => (
                        <EmailVerification
                            {...props}
                        />
                    )}
                />

                <Route path={"/"} component={Layout}/>

            </Switch>
        </Router>
    );
}

export default App;
